<template>
  <div class="wrap">
    <!-- 背景层 -->
    <div
      class="back"
      v-bind:style="`background-image: url(${require('../../assets/image/login.jpg')})`"
    ></div>
    <!-- 头部 -->
    <header class="header" :class="isShowMobile ? 'header-show-mobile-nav' : ''">
      <nav class="header-nav" :class="isMobileSearch ? 'header-show-mobile-search' : ''">
        <!-- logo 移动端搜索渐隐动画 -->
        <transition name="mobile-search-logo">
          <!-- logo -->
          <div v-show="!isMobileSearch" class="header-nav-logo">
            <router-link to="/" class="animation">Lyon</router-link>
            <div class="header-nav-mobile-menu" @click="eventMobileNav">
              <span class="header-nav-mobile-menu-line header-nav-mobile-menu-line-top"></span>
              <span class="header-nav-mobile-menu-line header-nav-mobile-menu-line-bottom"></span>
            </div>
          </div>
        </transition>
        <!-- 移动端展开菜单过渡动画 -->
        <transition name="mobile-search-input-box">
          <!-- 移动端搜索input样式 -->
          <div v-show="isShowMobile" class="header-nav-search-box-mobile">
            <div class="header-nav-search-box-mobile-input" @click="eventMobileSearch">
              <base-icon icon="search" />
              <span class="header-nav-search-box-mobile-input-placeholder">搜索</span>
            </div>
            <div @click="eventSearchCancel" class="header-nav-search-box-mobile-cancel">
              <span>取消</span>
            </div>
          </div>
        </transition>
        <!-- 导航条 -->
        <div class="header-nav-box">
          <!-- 移动端搜索时 导航过渡 -->
          <div class="header-nav-box-left-wrap">
            <transition name="mobile-search-nav">
              <div v-show="!isMobileSearch" class="mobile-search-transition-box">
                <!-- pc 端搜索时导航过渡 -->
                <transition name="search-nav">
                  <ul v-show="!isSearch" class="header-nav-list">
                    <li>
                      <router-link to="/" class="animation">博客</router-link>
                    </li>
                    <li>
                      <router-link to="/category" class="animation">分类</router-link>
                    </li>
                    <li>
                      <router-link to="/about" class="animation">关于</router-link>
                    </li>
                    <li class="header-nav-list-search">
                      <base-icon icon="search" class="icon" @click.native="eventPcSearch" />
                    </li>
                  </ul>
                </transition>
              </div>
            </transition>
            <!-- pc 搜索过渡 -->
            <transition name="search-box">
              <!-- 底部搜索表单 -->
              <div v-show="isSearch" class="header-nav-search-wrap">
                <div class="header-nav-search-box">
                  <form @submit.prevent="eventSearch">
                    <base-icon icon="search" class="icon" />
                    <input
                      v-model="searchValue"
                      v-focus="isSearch || isMobileSearch"
                      type="text"
                      placeholder="搜索"
                    />
                  </form>
                </div>
                <div
                  @click="eventSearchCancel"
                  class="search-cancel-button"
                  :class="isSearch ? 'search-cancel-button-show' : ''"
                ></div>
                <transition name="drop-wrap">
                  <div
                    v-show="isSearch || isShowMobile"
                    class="header-drop-wrap header-drop-wrap-search"
                    :class="{ 'header-drop-wrap-search-mobile': isShowMobile }"
                  >
                    <div v-inline-loading="{ show: isSearchLoading, color: 'black', size: 30 }">
                      <ul v-if="searchList.length > 0">
                        <li v-for="item in searchList" :key="item.articleId">
                          <router-link class="animation" :to="`/article/${item.articleId}`">{{
                            item.articleTitle
                          }}</router-link>
                        </li>
                      </ul>
                      <p v-else style="text-align: center"><span>空空的~</span></p>
                    </div>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
          <!-- 右侧登录及登录后显示头像 -->
          <div class="header-nav-box-right-wrap">
            <div class="header-nav-box-right-wrap-login">
              <div @click="eventClickHeader" class="header-nav-box-right-wrap-login-user-icon">
                <base-icon icon="user" />
              </div>
              <transition name="drop-wrap">
                <div class="header-drop-wrap" v-show="isShowDropWrap">
                  <!-- 登录后样式 -->
                  <template v-if="userIsLogin">
                    <div class="header-nav-box-right-wrap-login-drop-wrap">
                      <div
                        class="header-nav-box-right-wrap-login-drop-wrap-head"
                        v-bind:style="
                          `background-image:url(${$store.getters['base/STATIC_HOST']}${userData.userProfilePhoto})`
                        "
                      />
                      <div class="header-nav-box-right-wrap-login-drop-wrap-title">
                        <span>{{ userData.userName }}</span>
                      </div>
                      <ul>
                        <li>
                          <router-link class="animation" to="/center">个人中心</router-link>
                        </li>
                        <li>
                          <router-link class="animation" to="/logout">退出登录</router-link>
                        </li>
                      </ul>
                    </div>
                  </template>
                  <!-- 未登录样式 -->
                  <template v-else>
                    <div class="header-nav-box-right-wrap-login-drop-wrap">
                      <ul>
                        <li>
                          <router-link
                            class="animation"
                            :to="{
                              path: '/login',
                              query: {
                                redirect:
                                  $route.fullPath.indexOf('/login') === -1 ? $route.fullPath : ''
                              }
                            }"
                            >登录</router-link
                          >
                        </li>
                        <li>
                          <router-link class="animation" to="/register">注册</router-link>
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </transition>
            </div>
            <!-- 页面设置按钮 -->
            <div class="header-nav-box-right-wrap-settings" @click="eventClickSettings">
              <base-icon icon="settings" class="icon" />
              <span class="header-nav-box-right-wrap-settings-text">设置</span>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <!-- 内容 -->
    <div class="content">
      <!-- 插槽 -->
      <slot></slot>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <p>
        Copyright © {{ new Date().getFullYear() }}, <a :href="origin">{{ hostname }}</a> All Right
        Reserved.
      </p>
    </footer>
  </div>
</template>

<script>
/**
 * 基础页面组件
 */
export default {
  name: 'BasePageFrame',
  data() {
    return {
      isSearch: false, // 是否是搜索模式
      isMobileSearch: false, // 是否是移动端搜索模式
      isSearchLoading: false, // 搜索结果 loading
      searchList: [], // 搜索结果列表
      isShowMobile: false, // 展示移动端导航
      searchValue: '', // 搜索内容
      isShowDropWrap: false, // 头像下拉展开栏

      methodWindowResize: null // window 窗口缩放事件 唯一地址
    };
  },
  computed: {
    /**
     * 用户是否登录
     */
    userIsLogin() {
      return this.$store.getters['user/userIsLogin'];
    },
    /**
     * 用户数据
     */
    userData() {
      return this.$store.getters['user/userData'];
    },
    origin() {
      return window.location.origin;
    },
    hostname() {
      return window.location.hostname;
    }
  },
  mounted() {
    /**
     * 窗口变化时改变导航状态
     */
    window.addEventListener('resize', this.windowResizeEvent());
    /**
     * 解决切换导航时页面不能滑动问题
     */
    this.setViewShow();
  },
  destroyed() {
    /**
     * 页面销毁时取消事件监听
     */
    window.removeEventListener('resize', this.windowResizeEvent());
  },
  methods: {
    /**
     * pc 搜索
     */
    eventPcSearch() {
      this.isSearch = true;
      this.getHotSearch();
      this.searchValue = '';
    },
    /**
     * 移动端搜索
     */
    eventMobileSearch() {
      this.isMobileSearch = true;
      this.getHotSearch();
      this.searchValue = '';
    },
    /**
     * 展示搜索结果
     */
    eventSearch() {
      this.isSearchLoading = true;
      this.$api
        .articlesList({
          query: { key: this.searchValue }
        })
        .then(res => {
          this.searchList = res.articleList;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    },
    /**
     * 热门搜索列表
     */
    getHotSearch() {
      this.isSearchLoading = true;
      this.$api
        .articlesHotList()
        .then(res => {
          this.searchList = res;
        })
        .finally(() => {
          this.isSearchLoading = false;
        });
    },
    /**
     * pc/移动端 取消搜索
     */
    eventSearchCancel() {
      this.isSearch = false;
      this.isMobileSearch = false;
    },
    /**
     * 移动端导航切换
     */
    eventMobileNav() {
      this.isShowMobile = !this.isShowMobile;
      this.isShowMobile ? this.setViewHidden() : this.setViewShow();
      // 取消pc搜索
      this.isSearch && this.eventSearchCancel();
      // 关闭头像下拉栏
      this.isShowDropWrap = false;
    },
    setViewHidden() {
      this.$tools.stopBodyScroll(true);
    },
    setViewShow() {
      this.$tools.stopBodyScroll(false);
    },
    /**
     * 设置点击事件
     */
    eventClickSettings() {
      this.$liang.mask.modal({
        content: '该功能拼命开发中~~',
        confirmText: '好的吧'
      });
    },
    /**
     * 点击头像
     */
    eventClickHeader() {
      this.isShowDropWrap = !this.isShowDropWrap;
    },
    /**
     * window 窗口缩放事件
     */
    windowResizeEvent() {
      let thisV = this;
      let oldWindowWidth = window.innerWidth;
      /**
       * 全局唯一 window 窗口缩放事件(存在返回地址 不存在新建)
       */
      return (thisV.methodWindowResize =
        thisV.methodWindowResize ||
        /**
         * 函数防抖
         */
        thisV.$tools.debounce(function() {
          /**
           * 解決 Resize 在 Safari 之Bug
           * 先保存一个视窗的width，当resize触发时再跟当前的width进行对比，这样一来就能判断视窗的width是否变化了。
           */
          if (oldWindowWidth != window.innerWidth) {
            if (document.body.clientWidth > 500) {
              /**
               * 解决缩放浏览器时页面不能滑动问题
               */
              thisV.setViewShow();
              // 取消移动端 pc 搜索 移动端搜索状态
              thisV.isMobileSearch = thisV.isSearch = thisV.isShowMobile = false;
            }
          }
        }, 50));
    }
  },
  directives: {
    // 自定义聚焦指令
    focus: {
      update: function(el, binding) {
        binding.value && el.focus();
      }
    }
  }
};
</script>

<style scoped lang="scss">
/** 容器层 */
.wrap {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}

/** 背景层 */
.back {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(5px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

/** 头部样式 */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.8);
  color: rgba(0, 0, 0, 0.9);
  font-size: 20px;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -5px;
  height: 70px;
  transition: background 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1),
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);

  /** 头部导航 */
  .header-nav {
    max-width: 1210px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    position: relative;

    a {
      color: inherit;
    }

    /** logo */
    .header-nav-logo {
      font-size: 1.2em;
      font-weight: 900;

      /** 移动端logo 及 菜单样式 */
      .header-nav-mobile-menu {
        position: absolute;
        top: 10px;
        left: 0;
        width: 30px;
        height: 30px;
        display: none;

        .header-nav-mobile-menu-line {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -50%;
          width: 20px;
          height: 1px;
          transform-origin: center center;
          transition: transform 0.3s;
          transition-timing-function: cubic-bezier(0.04, 0.04, 0.12, 0.96),
            cubic-bezier(0.04, 0.04, 0.12, 0.96);

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.9);
            transition: transform 0.3s;
            transition-delay: 0.15s;
            transition-timing-function: cubic-bezier(0.04, 0.04, 0.12, 0.96),
              cubic-bezier(0.04, 0.04, 0.12, 0.96);
          }
        }

        .header-nav-mobile-menu-line-top {
          &::before {
            transform: translateY(-5px);
          }
        }

        .header-nav-mobile-menu-line-bottom {
          &::before {
            transform: translateY(5px);
          }
        }
      }
    }

    /** 移动端搜索样式 */
    .header-nav-search-box-mobile {
      padding: 15px 20px;
      border-bottom: 1px solid #ccc;
      padding-top: 0;
      position: relative;
      transition: padding 0.3s;
      font-size: 0.8em;

      .header-nav-search-box-mobile-input {
        height: 36px;
        border-radius: 4px;
        text-align: left;
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 36px;
        vertical-align: middle;
        color: #666;

        .header-nav-search-box-mobile-input-placeholder {
          margin-left: 10px;
        }
      }

      .header-nav-search-box-mobile-cancel {
        position: absolute;
        color: #259eff;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        letter-spacing: 0.5em;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s;
      }
    }

    /** 导航链接样式 */
    .header-nav-box {
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 0.8em;
      margin-left: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-nav-box-left-wrap {
        .header-nav-list {
          li {
            display: inline-block;
            margin-left: 40px;

            &:first-child {
              margin-left: 0;
            }
          }

          .icon {
            cursor: pointer;
          }
        }

        /** 搜索表单 */
        .header-nav-search-wrap {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 4;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transform: translateY(-50%);

          .header-nav-search-box {
            input {
              font-size: 0.95em;
              margin-left: 10px;
              width: 200px;
              padding: 5px 0;
              display: inline-block;
              line-height: 1.2;
              /** 解决ios placeholder不居中 */
              background: none;
            }
          }

          /** 取消搜索按钮 pc */
          .search-cancel-button {
            position: relative;
            width: 17px;
            height: 17px;
            cursor: pointer;
            transition: opacity 0.4s;
            opacity: 0;
            visibility: hidden;
            margin-left: 10px;

            &::before,
            &::after {
              content: '';
              position: absolute;
              height: 100%;
              width: 2px;
              background: rgba(0, 0, 0, 0.9);
              position: absolute;
              top: 0;
              z-index: 1;
              transition: transform 0.4s;
            }

            &::before {
              right: 0;
              transform-origin: right bottom;
            }

            &::after {
              left: 0;
              transform-origin: left bottom;
            }

            &.search-cancel-button-show {
              opacity: 1;
              visibility: visible;

              &::before {
                transform: rotate(-45deg) scale3d(1, 1.3, 1);
              }

              &::after {
                transform: rotate(45deg) scale3d(1, 1.3, 1);
              }
            }
          }
        }
      }

      /** 右侧登录及登录后显示头像 */
      .header-nav-box-right-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-nav-box-right-wrap-login {
          transition-delay: 0.6s;
          position: relative;

          .header-nav-box-right-wrap-login-user-icon {
            cursor: pointer;
            font-size: 20px;
          }

          .header-nav-box-right-wrap-login-drop-wrap {
            min-width: 200px;

            /** 头像样式 */
            .header-nav-box-right-wrap-login-drop-wrap-head {
              overflow: hidden;
              border-radius: 100px;
              width: 60px;
              height: 60px;
              box-sizing: border-box;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 50% 50%;
              transition: width 0.5s, height 0.5s;
              background-color: #ccc;
              display: inline-block;
              text-align: center;
              margin-top: -10px;
            }

            .header-nav-box-right-wrap-login-drop-wrap-title {
              margin-bottom: 20px;
              white-space: pre-wrap;
              font-weight: 900;
              font-size: 1.2em;
            }

            ul {
              li {
                margin-top: 20px;

                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }
        }

        .header-nav-box-right-wrap-settings {
          margin-left: 20px;
          cursor: pointer;
          font-size: 20px;

          .header-nav-box-right-wrap-settings-text {
            display: none;
            margin-left: 10px;
          }
        }
      }

      /** 下拉弹出框 */
      .header-drop-wrap {
        position: absolute;
        top: 100%;
        right: 0;
        padding: 20px;
        background: #eee;
        border-radius: 5px;
        line-height: normal;
        margin-top: 10px;
        white-space: nowrap;
        text-align: center;
        min-width: 150px;
        box-sizing: border-box;
        font-size: 16px;

        &::before {
          content: '';
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #eee;
          position: absolute;
          top: 0;
          right: 3px;
          transform: translateY(-100%);
        }
      }

      .header-drop-wrap-search {
        width: 100%;
        margin-top: 20px;
        min-height: 120px;
        text-align: left;

        &::before {
          display: none;
        }

        li {
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 2;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .header-drop-wrap-search-mobile {
        background: none;

        li {
          a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .drop-wrap-enter-active {
        transition: opacity 0.3s, transform 0.3s;
      }

      .drop-wrap-enter,
      .drop-wrap-leave-to {
        opacity: 0;
        transform: translateY(50%);
      }
    }
  }
}

.content {
  padding-bottom: 80px;
  padding-top: 70px;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  text-align: center;
  font-size: 14px;
  color: rgba(158, 158, 158, 0.5);
  box-sizing: border-box;
  padding: 30px 0 20px;

  a {
    color: inherit;
  }
}

/** 移动端媒体查询 */
@media only screen and (max-width: 700px) {
  .header {
    height: 50px;
    line-height: 50px;

    /** 移动端导航菜单展开样式 */
    &.header-show-mobile-nav {
      height: 100%;
      box-shadow: unset;

      .header-nav {
        .header-nav-box {
          display: block;
          position: static;

          .header-nav-box-left-wrap {
            width: auto;
            height: auto;
            opacity: 1;
          }

          .header-nav-box-right-wrap {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            .header-nav-box-right-wrap-login {
              visibility: hidden;
              transition-delay: 0s;
              opacity: 0;
              /** 解决 firefox 下收起时 闪出问题 */
            }

            .header-nav-box-right-wrap-settings {
              width: auto;
              height: auto;
              visibility: visible;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin-left: 0;
              border-top: 1px solid #ccc;
              text-align: left;
              padding-left: 20px;
              transition-delay: 0.3s;
              font-size: inherit;

              .header-nav-box-right-wrap-settings-text {
                display: inline-block;
              }
            }
          }
        }

        /** logo */
        .header-nav-logo {
          /** 移动端导航菜单 */
          .header-nav-mobile-menu {
            .header-nav-mobile-menu-line {
              transition-delay: 0.1s;

              &::before {
                transition-delay: 0s;
              }
            }

            .header-nav-mobile-menu-line-top {
              transform: rotate(45deg);

              &::before {
                transform: none;
              }
            }

            .header-nav-mobile-menu-line-bottom {
              transform: rotate(-45deg);

              &::before {
                transform: none;
              }
            }
          }
        }
      }
    }

    .header-nav {
      text-align: center;
      padding: 0;
      width: 90%;
      display: block;

      /** 移动端搜索样式 */
      &.header-show-mobile-search {
        .header-nav-search-box-mobile {
          padding-top: 15px;
          padding-right: 90px;

          .header-nav-search-box-mobile-cancel {
            opacity: 1;
            visibility: visible;
            z-index: 6;
          }

          .header-nav-search-box-mobile-input {
            background: rgba(215, 215, 215, 0.8);

            .icon,
            .header-nav-search-box-mobile-input-placeholder {
              opacity: 0;
            }
          }
        }

        .header-nav-box {
          .header-nav-box-left-wrap {
            .header-nav-search-wrap {
              display: block !important;
              z-index: 5;
              top: 15px;
              left: 20px;
              right: 20px;
              height: 36px;
              line-height: 36px;
              color: #666;
              transform: translateY(0);

              .header-nav-search-box {
                text-align: left;

                input {
                  font-size: 1em;
                  width: 80%;
                }

                .icon {
                  margin-left: 10px;
                }
              }

              .search-cancel-button {
                display: none;
              }
            }
          }

          .header-nav-box-right-wrap {
            .header-nav-box-right-wrap-settings {
              visibility: hidden;
              transition-delay: 0s;
              opacity: 0;
            }
          }
        }
      }

      a {
        &::after {
          display: none;
        }
      }

      .header-nav-logo {
        position: relative;

        .header-nav-mobile-menu {
          display: block;
        }
      }

      /** 正常缩小时的样式 */
      .header-nav-box {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: auto;
        margin-left: 0;
        box-sizing: border-box;

        .header-nav-box-left-wrap {
          width: 0;
          height: 0;
          overflow: hidden;
          opacity: 0;
          transition: opacity 0.4s;

          .header-nav-list {
            position: static;
            text-align: left;
            padding: 0 40px;

            li {
              display: block;
              margin: 0;
              border-top: 1px solid #ccc;

              &:first-child {
                border-top: none;
              }

              &.header-nav-list-search,
              &.header-nav-list-setting {
                display: none;
              }
            }
          }
        }

        .header-nav-box-right-wrap {
          .header-nav-box-right-wrap-settings {
            visibility: hidden;
            width: 0;
            height: 0;
            margin: 0;
          }
        }

        a {
          display: block;
        }
      }
    }
  }

  .content {
    padding-top: 50px;
  }

  .footer {
    font-size: 12px;
  }
}

/** 是否支持某些CSS属性 */
@supports not (backdrop-filter: blur(20px)) {
  .header {
    background: rgba(255, 255, 255, 1);
  }
}
</style>
<style scoped lang="scss">
/** 过渡动画 */
/** pc-search 导航条过渡 */
.search-nav-enter-active {
  transition: none 1s;

  li {
    animation: search-nav-hide-li 0.4s both;

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        animation-delay: 0.05s * ($i - 1);
      }
    }
  }
}

.search-nav-leave-active {
  transition: none 1s;

  li {
    animation: search-nav-show-li 0.4s both;

    @for $i from 9 through 1 {
      &:nth-child(#{$i}) {
        animation-delay: 0.05s * ($i - 1);
      }
    }
  }
}

@keyframes search-nav-show-li {
  0% {
    opacity: 1;
    transform: none;
    animation-timing-function: cubic-bezier(0.2727, 0.0986, 0.8333, 1);
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(0.7);
  }
}

@keyframes search-nav-hide-li {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: none;
    animation-timing-function: cubic-bezier(0.2727, 0.0986, 0.8333, 1);
  }
}

/** pc-search 输入框过渡 */
.search-box-enter-active {
  transition: none 1s;

  .header-nav-search-box {
    animation: search-box-show 0.4s 0.2s both;
  }
}

@keyframes search-box-show {
  0% {
    animation-timing-function: cubic-bezier(0.67, 0, 0.33, 1);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/** 移动端搜索导航条过渡 */
.mobile-search-nav-leave-active {
  animation: mobile-search-nav-leave 0.5s both;

  & + .header-nav-search-wrap {
    opacity: 0;
  }
}

.mobile-search-nav-enter-active {
  animation: mobile-search-nav-show 0.5s both;
}

@keyframes mobile-search-nav-leave {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 0;
  }
}

@keyframes mobile-search-nav-show {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
  }
}

/** 移动端搜索logo过渡 */
.mobile-search-logo-leave-active {
  animation: mobile-search-logo-leave 0.5s both;

  & + .header-nav-search-box-mobile {
    .header-nav-search-box-mobile-input {
      .icon,
      .header-nav-search-box-mobile-input-placeholder {
        opacity: 1 !important;
      }
    }
  }
}

.mobile-search-logo-enter-active {
  animation: mobile-search-logo-show 0.5s both;

  & + .header-nav-search-box-mobile {
    .header-nav-search-box-mobile-input {
      .icon,
      .header-nav-search-box-mobile-input-placeholder {
        opacity: 1;
      }
    }
  }
}

@keyframes mobile-search-logo-leave {
  0% {
    opacity: 1;
    height: 50px;
    transform: translateY(0);
  }

  70% {
    transform: translateY(-25px);
    opacity: 0;
    height: 0;
  }

  100% {
    height: 0;
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes mobile-search-logo-show {
  0% {
    opacity: 0;
    height: 0;
    transform: translateY(-50px);
  }

  70% {
    opacity: 1;
    height: 50px;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    height: 50px;
    transform: translateY(0);
  }
}

/** 移动端搜索input */
.mobile-search-input-box-leave-active {
  animation: mobile-search-input-box-leave 0.5s both;
  z-index: -1;
}

.mobile-search-input-box-enter-active {
  animation: mobile-search-input-box-show 0.5s both;
  z-index: -1;
}

@keyframes mobile-search-input-box-leave {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  70% {
    transform: translateY(-25px);
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

@keyframes mobile-search-input-box-show {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
