<template>
  <div class="user-background">
    <div
      v-if="backgroundImage"
      class="user-background-image"
      :style="`background-image:url(${$store.getters['base/STATIC_HOST']}${backgroundImage})`"
    />
    <div class="user-background-people">
      <div
        v-if="image"
        class="user-background-people-head"
        :style="`background-image:url(${$store.getters['base/STATIC_HOST']}${image})`"
      />
      <div class="user-background-people-name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserBackground',
  props: {
    name: {
      type: String,
      default: '',
      required: true
    },
    image: {
      type: String,
      default: '',
      required: true
    },
    background: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    backgroundImage: function() {
      return this.background || this.image;
    }
  }
};
</script>

<style scoped lang="scss">
/**关于banner图 */
.user-background {
  margin: -20px;
  position: relative;
  height: 250px;
  .user-background-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }
  }
  .user-background-people {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 3;
    transform: translateY(-50%);
    .user-background-people-head {
      display: inline-block;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 100%;
      overflow: hidden;
      width: 100px;
      height: 100px;
      cursor: pointer;
      transition: transform 2s, width 0.5s, height 0.5s;
      background: #ccc;
      min-width: 100px;
      min-height: 100px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      &:hover {
        transform: rotate(3600deg);
      }
    }
    .user-background-people-name {
      font-weight: bolder;
      font-size: 1.5em;
      color: #fff;
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 700px) {
  .user-background {
    height: 200px;
    .user-background-people {
      .user-background-people-head {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
      }
    }
  }
}
</style>
