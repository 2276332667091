/*
 * @Author: CloverLiang
 * @Date: 2020-04-21 18:48:19
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-15 16:11:48
 * vuex 状态管理
 */
import Vue from 'vue';
import Vuex from 'vuex';

import moduleUser from './user';
import moduleComment from './comment';
import moduleBase from './base';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    base: moduleBase,
    user: moduleUser,
    comment: moduleComment
  }
});
