<template>
  <base-page-frame>
    <div class="form-wrap">
      <base-wrap>
        <base-form
          v-bind:model="form"
          v-bind:rules="rules"
          v-on:base-submit="eventSubmit"
          ref="register-form"
        >
          <div class="form-wrap-title">欢迎你</div>
          <!-- 邮箱 -->
          <base-input
            class="input-wrap"
            type="text"
            placeholder="邮箱"
            v-model="form.email"
            name="email"
            prop="email"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="mail" />
            </template>
          </base-input>
          <!-- 验证码 -->
          <base-input
            class="input-wrap"
            type="text"
            placeholder="验证码"
            v-model="form.verifyCode"
            name="verify-code"
            prop="verifyCode"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="verify-user" />
            </template>
            <template v-slot:last>
              <base-button class="form-wrap-verify" v-on:click="eventGetVerifyCode">{{
                verifyCodeText
              }}</base-button>
            </template>
          </base-input>
          <!-- 密码 -->
          <base-input
            class="input-wrap"
            type="password"
            placeholder="密码"
            v-model="form.password"
            name="password"
            prop="password"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="lock" />
            </template>
          </base-input>
          <!-- 昵称 -->
          <base-input
            class="input-wrap"
            type="text"
            placeholder="昵称"
            v-model="form.nickname"
            name="nickname"
            prop="nickname"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="user" />
            </template>
          </base-input>
          <!-- 注册按钮 -->
          <base-button type="submit">注册</base-button>
        </base-form>
        <!-- 底部提示 -->
        <div class="form-wrap-bottom">
          <div class="form-wrap-bottom-right"></div>
          <div class="form-wrap-bottom-left">
            <span>已有账号？</span>
            <router-link
              class="animation"
              v-bind:to="{
                path: '/login',
                query: { redirect: $route.fullPath }
              }"
              >立即登录</router-link
            >
          </div>
        </div>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
import md5 from 'md5';
import { validateEmailPass } from '../utils';

export default {
  name: 'PageRegister',
  data() {
    return {
      form: {
        email: '',
        verifyCode: '',
        password: '',
        nickname: ''
      }, // form 表单元素
      rules: {
        email: [
          { isRequired: true, trigger: 'blur' },
          { validator: validateEmailPass, trigger: 'blur' }
        ],
        verifyCode: [{ isRequired: true, trigger: 'blur' }],
        password: [{ isRequired: true, trigger: 'blur' }]
      }, // 验证规则
      verifyCodeTime: 60, // 验证码倒计时时间
      verifyCodeTimer: null, // 验证码计时器id
      verifyCodeText: '获取验证码' // 验证码显示文字
    };
  },
  destroyed() {
    clearTimeout(this.verifyCodeTimer);
  },
  methods: {
    /**
     * 提交注册
     */
    eventSubmit(canSubmit) {
      if (canSubmit.valid) {
        // 可以注册
        let closeLoading = this.$liang.mask.loading();
        this.$api
          .userRegister({
            params: {
              userAccount: this.form.email,
              userPassword: md5(this.form.password),
              userName: this.form.nickname,
              userVerifyCode: this.form.verifyCode
            }
          })
          .then(() => {
            this.$liang.mask.toast({
              content: '注册成功，欢迎你',
              showMask: true,
              complete: () => {
                this.$router.replace({ path: '/login' });
              }
            });
          })
          .finally(() => {
            closeLoading();
          });
      }
    },
    /**
     * 获取验证码
     */
    eventGetVerifyCode() {
      this.$refs['register-form'].getCurrentValidationState('email', isPass => {
        if (isPass && this.verifyCodeText === '获取验证码') {
          // 验证通过发送验证码
          let closeLoading = this.$liang.mask.loading();
          this.$api
            .userVerify({ uri: { mail: this.form.email } })
            .then(() => {
              // 验证码发送成功 开始倒计时
              this.$liang.mask.toast({ content: '验证码发送成功，请注意查收' });
              this.timeoutVerifyCode();
            })
            .finally(() => {
              closeLoading();
            });
        }
      });
    },
    /**
     * 验证码倒计时
     */
    timeoutVerifyCode() {
      let timeoutTime = this.verifyCodeTime;

      let timeoutVerifyFn = () => {
        timeoutTime--;
        this.verifyCodeText = `${timeoutTime}秒后重新获取`;
        if (timeoutTime <= 0) {
          clearTimeout(this.verifyCodeTimer);
          this.verifyCodeText = '获取验证码';
          return;
        }
        this.verifyCodeTimer = setTimeout(() => {
          timeoutVerifyFn();
        }, 1000);
      };
      timeoutVerifyFn();
    }
  }
};
</script>

<style scoped lang="scss">
.form-wrap {
  padding-top: 20px;
  .form-wrap-title {
    font-size: 1.5em;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input-wrap {
    border-bottom: 1px solid #999;
    margin-bottom: 30px;
    .input-wrap-icon {
      font-size: 1.5em;
    }
    .form-wrap-verify {
      padding-bottom: 2px;
    }
  }
  .form-wrap-bottom {
    margin-top: 50px;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: inherit;
  }
}
</style>
