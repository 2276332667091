/*
 * @Author: CloverLiang
 * @Date: 2020-04-03 12:22:30
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-05-24 15:31:31
 * 所有接口
 */

import axios from './http'; // 导入 api

/**
 * 首页接口
 */
export const articlesList = data => {
  return axios.setPath('website/front/article').get(data);
};
/**
 * 获取网站基本配置
 */
export const websiteConfig = data => {
  return axios.setPath('website/config').get(data);
};
/**
 * 热门搜索
 */
export const articlesHotList = data => {
  return axios.setPath('website/front/article/hot').get(data);
};
/**
 * 获取文章详细信息
 */
export const articleDetail = data => {
  return axios.setPath('website/front/article/{id}').get(data);
};
/**
 * 获取文章分类列表
 */
export const articleCatagory = data => {
  return axios.setPath('website/front/article/category').get(data);
};
/**
 * 获取指定分类下的文章列表
 */
export const articleCatagoryDetail = data => {
  return axios.setPath('website/front/article/category').get(data);
};
/**
 * 获取评论详细信息
 */
export const commentsList = data => {
  return axios.setPath('website/front/comment/{id}').get(data);
};
/**
 * 用户登录
 */
export const userLogin = data => {
  return axios.setPath('website/front/user').patch(data);
};
/**
 * 增加文章评论
 */
export const commentAdd = data => {
  return axios.setPath('website/front/comment/{id}').post(data);
};
/**
 * 发送注册验证码
 */
export const userVerify = data => {
  return axios.setPath('website/front/verify/{mail}').post(data);
};
/**
 * 用户注册
 */
export const userRegister = data => {
  return axios.setPath('website/front/user').post(data);
};
/**
 * 用户重置密码
 */
export const userForget = data => {
  return axios.setPath('website/front/user/password').patch(data);
};
/**
 * 获取用户信息
 */
export const getUserInfo = data => {
  return axios.setPath('website/front/user').get(data);
};
/**
 * 获取访客用户信息
 */
export const getVisitorUserInfo = data => {
  return axios.setPath('website/front/user/{id}').get(data);
};
/**
 * 上传图片
 */
export const uploadImage = data => {
  return axios.setPath('common/file').post({
    config: { header: { 'Content-Type': 'multipart/form-data' } },
    ...data
  });
};
/**
 * 更新用户信息
 */
export const updateUserInfo = data => {
  return axios.setPath('website/front/user').put(data);
};
/**
 * 获取用户通知列表
 */
export const getUserNotification = data => {
  return axios.setPath('website/front/user/notification').get(data);
};

// 默认全部导出
export default {
  websiteConfig,
  articlesList,
  articleDetail,
  commentsList,
  userLogin,
  commentAdd,
  userVerify,
  userRegister,
  userForget,
  getUserInfo,
  uploadImage,
  updateUserInfo,
  articlesHotList,
  articleCatagory,
  articleCatagoryDetail,
  getVisitorUserInfo,
  getUserNotification
};
