<template>
  <ul class="user-account">
    <li class="user-account-item">
      <div class="user-account-item-info">登录账户邮箱：</div>
      <div class="user-account-item-box">{{ userData.userAccount }}</div>
    </li>
    <li class="user-account-item">
      <div class="user-account-item-info">上次登录时间：</div>
      <div class="user-account-item-box">{{ userData.userLastLoginTime }}</div>
    </li>
    <li class="user-account-item">
      <div class="user-account-item-info">上次登录IP地址：</div>
      <div class="user-account-item-box">{{ userData.userLastLoginIp }}</div>
    </li>
    <li class="user-account-item user-account-item-button-box">
      <base-button class="user-account-item-button-box-button">
        <router-link to="/forget">更改密码</router-link>
      </base-button>
      <base-button
        class="user-account-item-button-box-button"
        v-bind:bg-color="`rgba(215, 25, 26, 1)`"
      >
        <router-link to="/logout">退出登录</router-link>
      </base-button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'UserAccount',
  props: {
    originUserData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      userData: {
        userAccount: '', // 用户账户地址
        userLastLoginTime: '', // 用户上一次登录时间
        userLastLoginIp: '' // 用户上一次登录ip地址
      }
    };
  },
  mounted() {
    this.updateUserData(this.originUserData);
  },
  watch: {
    /**
     * 确保本地userData保持最新
     */
    originUserData: function(n) {
      this.updateUserData(n);
    }
  },
  methods: {
    updateUserData(originUserData) {
      if (originUserData.userAccount && originUserData.userAccount !== '') {
        let userData = this.$tools.pickObject(originUserData, [
          'userAccount',
          'userLastLoginTime',
          'userLastLoginIp'
        ]);
        this.userData = userData;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-account {
  .user-account-item {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    .user-account-item-info {
      flex-shrink: 0;
      width: 150px;
      text-align: right;
    }
    .user-account-item-box {
      background: rgba(134, 157, 179, 1);
      text-align: center;
      padding: 5px 0;
      box-sizing: border-box;
      border-radius: 3px;
      color: rgb(84, 84, 84);
      width: 100%;
    }
  }
  .user-account-item-button-box {
    justify-content: center;
    .user-account-item-button-box-button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    a {
      color: inherit;
    }
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .user-account {
    .user-account-item {
      .user-account-item-info {
        width: 130px;
        text-align: left;
      }
    }
  }
}
</style>
