/*
 * @Author: CloverLiang
 * @Date: 2020-04-03 12:17:14
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-15 18:53:35
 * axios 配置文件
 */
export default {
  // 基础url前缀
  baseURL: process.env.VUE_APP_API_URL,
  // 请求头信息
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  // 参数
  data: {},
  // 设置超时时间
  timeout: 300000,
  // 携带凭证
  withCredentials: false,
  // 返回数据类型
  responseType: 'json'
};
