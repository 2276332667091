<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
export default {
  name: 'App',
  async created() {
    this.$store.commit('base/setConfig', await this.$api.websiteConfig());
  }
};
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
