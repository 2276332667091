<template>
  <div class="comment-box" :class="isChildComment ? 'comment-box-child' : ''">
    <div
      class="comment-box-left"
      :style="
        `background-image:url(${$store.getters['base/STATIC_HOST']}${commentData.commentUserProfilePhoto})`
      "
    ></div>
    <div class="comment-box-right">
      <div class="comment-box-right-name">
        <span>{{ commentData.commentUserName }}</span>
      </div>
      <div class="comment-box-right-content">
        <base-parse-html v-bind:html-str="commentData.commentContent"></base-parse-html>
      </div>
      <div class="comment-box-right-bottom">
        <div class="comment-box-right-bottom-time">
          <span>{{ commentData.commentTime }}</span>
        </div>
        <div class="comment-box-right-bottom-replay" @click="eventCommentReply">
          <base-icon icon="chat" class="comment-box-right-bottom-replay-icon" /><span>回复</span>
        </div>
      </div>
      <!-- 回复区域 -->
      <transition name="comment-reaply-area">
        <div class="comment-reply-area" v-if="commentData.commentIsReply">
          <comment-input-area
            v-bind:is-reply="true"
            v-bind:placeholder-str="'回复' + commentData.commentUserName + ':'"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CommentInputArea from './CommentInputArea';

export default {
  name: 'CommentDetail',
  props: {
    isChildComment: {
      type: Boolean,
      required: false,
      default: false // true => 子评论状态 false => 父评论状态
    },
    commentData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  methods: {
    /**
     * 回复点击事件
     */
    eventCommentReply: function() {
      if (!this.commentData.userIsLogin) {
        return this.$liang.mask.modal({ content: '啊咧，登录后才能评论哦！' });
      }
      let indexArr = [];
      if (this.isChildComment) {
        // 子评论状态
        indexArr = [this.commentData.commentParentIndex, this.commentData.commentIndex];
      } else {
        // 父评论状态
        indexArr = [this.commentData.commentIndex];
      }
      this.$store.commit({
        type: 'comment/changeCommentReplyStatus',
        indexArr: indexArr,
        status: !this.commentData.commentIsReply
      });
    }
  },
  components: {
    'comment-input-area': CommentInputArea
  }
};
</script>

<style scoped lang="scss">
.comment-box {
  /** 头像 */
  .comment-box-left {
    overflow: hidden;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin-right: 20px;
    float: left;
    transition: width 0.5s, height 0.5s;
    background-color: #ccc;
    border: 1px solid #9e9e9e;
  }
  /** 右侧内容 */
  .comment-box-right {
    text-align: left;
    margin-left: 70px;
    padding-bottom: 20px;
    position: relative;
    .comment-box-right-name {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .comment-box-right-content {
      margin-bottom: 10px;
    }
    .comment-box-right-bottom {
      font-size: 90%;
      .comment-box-right-bottom-time {
        display: inline-block;
        margin-right: 20px;
        color: #666;
      }
      .comment-box-right-bottom-replay {
        display: inline-block;
        cursor: pointer;
        .comment-box-right-bottom-replay-icon {
          margin-right: 5px;
        }
      }
    }
  }
  /** 回复区域样式 */
  .comment-reply-area {
    position: relative;
    left: 0;
    right: 0;
    background: #eee;
    border-radius: 3px;
    z-index: 2;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-top: 10px;
    &::before {
      content: '';
      position: absolute;
      border-width: 8px;
      border-style: solid;
      border-color: #eee;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      top: -16px;
      z-index: 2;
      left: 90px;
    }
  }
}
/** 子评论样式 */
.comment-box-child {
  .comment-box-left {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .comment-box-right {
    margin-left: 50px;
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .comment-box {
    font-size: 95%;
    .comment-box-left {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
    .comment-box-right {
      margin-left: 55px;
    }
  }
  /** 子评论 */
  .comment-box-child {
    .comment-box-left {
      width: 35px;
      height: 35px;
    }
    .comment-box-right {
      margin-left: 45px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .comment-box {
    font-size: 90%;
    .comment-box-left {
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }
    .comment-box-right {
      margin-left: 40px;
    }
  }
  /** 子评论 */
  .comment-box-child {
    .comment-box-left {
      width: 30px;
      height: 30px;
    }
    .comment-box-right {
      margin-left: 35px;
    }
  }
}
/** 评论输入区域过渡效果 */
.comment-reaply-area-enter-active {
  transition: opacity 0.3s, transform 0.3s;
}
.comment-reaply-area-enter,
.comment-reaply-area-leave-to {
  opacity: 0;
  transform: translateY(50%);
}
</style>
