<script>
export default {
  name: 'PageLogout',
  mounted() {
    this.$store.commit('user/userLogout');
    this.$router.replace({ path: '/' });
  },
  /**
   * 无渲染组件
   */
  render() {
    return {};
  }
};
</script>
