<template>
  <div v-if="articleId !== -1" class="comment-area">
    <!-- 输入和提示登录 -->
    <div class="comment-wrap">
      <div v-if="!userIsLogin" class="comment-wrap-login">
        <span>啊咧，</span>
        <router-link
          class="animation"
          v-bind:to="{
            path: '/login',
            query: { redirect: $route.fullPath }
          }"
        >
          <base-icon icon="login" class="comment-wrap-login-icon" />登录
        </router-link>
        <span>后才能评论哦！</span>
      </div>
      <!-- 输入区域 -->
      <div v-else class="comment-wrap-input">
        <comment-input-area />
      </div>
    </div>
    <div class="comment-wrap" v-inline-loading="{ show: !isMounted, color: 'black' }">
      <!-- 没有评论提示 -->
      <div v-if="paginationData.totalAmount <= 0" class="comment-no-data">
        <span>惊了！竟然还没有评论</span>
      </div>
      <div v-else class="comment-data">
        <!-- 评论列表 -->
        <ul class="comment-list">
          <!-- 评论内容 -->
          <li
            class="comment-list-item"
            v-for="commentItem in commentsList"
            v-bind:key="commentItem.commentId"
          >
            <comment-detail v-bind:comment-data="commentItem" />
            <!-- 子评论列表 -->
            <ul
              v-if="commentItem.commentChildList && commentItem.commentChildList.length > 0"
              class="comment-child-list"
            >
              <li
                class="comment-child-list-item"
                v-for="commentItemChildItem in commentItem.commentChildList"
                v-bind:key="commentItemChildItem.commentId"
              >
                <comment-detail
                  v-bind:is-child-comment="true"
                  v-bind:comment-data="commentItemChildItem"
                />
              </li>
            </ul>
          </li>
        </ul>
        <!-- 分页 -->
        <!-- 只有这些了 -->
        <div v-if="commentsList.length >= paginationData.totalAmount" class="comment-no-more">
          <span class="comment-no-more-text">只有这些了</span>
          <base-icon icon="forbid" />
        </div>
        <!-- 查看更多 -->
        <div v-else class="comment-load-more">
          <a v-on:click="eventNextPage" href="javascript:void(0)" class="animation">
            <span class="comment-load-more-text">查看更多</span>
            <base-icon icon="arrow-down" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 评论区
import CommentDetail from './components/CommentDetail';
// 回复区域
import CommentInputArea from './components/CommentInputArea';
export default {
  name: 'CommentArea',
  props: {
    // 文章 id
    articleId: {
      type: [Number, String],
      required: true,
      default: -1
    },
    // 文章作者 id
    articleAuthorId: {
      type: [Number, String],
      required: true,
      default: -1
    }
  },
  data() {
    return {
      // 格式化后的数据
      commentsList: [],
      // 上一次打开的回复区域下标
      oldCommentIndexArr: [],
      /**
       * 页码相关
       */
      paginationData: {
        currentPageNumber: 1, // 当前页数
        constNumberOfPages: 10, // 固定每页数量 不更改
        lastCurrentPageNumber: 1, // 下一页之前保存当前页数
        numberOfPages: 10, // 动态每页数量
        totalPages: 0, // 总页数
        totalAmount: 0 // 总数量
      },
      /**
       * 数据是否加载完成
       */
      isMounted: false,
      closeLoading: null
    };
  },
  computed: {
    /**
     * 用户登录状态
     */
    userIsLogin: function() {
      return this.$store.getters['user/userIsLogin'];
    }
  },
  mounted() {
    // 获取评论数据
    this.getCommentsList();
  },
  watch: {
    /**
     * 回复区域是否开启 (不能观察 commentIsOpenReplyArea 此属性 因为多次点击回复时 此属性不变 仍为true)
     */
    '$store.state.comment.commentReplyIndexArr': function() {
      this.eventCommentOpenReplyArea();
    },
    /**
     * 提交评论或回复
     */
    '$store.state.comment.commentIsReadyToSubmit': function() {
      this.eventCommentSubmit();
    }
  },
  methods: {
    /**
     * 格式化评论数据
     */
    formatCommentData: function(originData = [], isChild = false, parentIndex = -1) {
      let processData = [];
      originData.forEach((ele, index) => {
        let processCommentObject = {
          commentIndex: index, // 评论下标
          commentId: ele.commentsId, // 评论id
          commentParentId: ele.commentsParentId, // 父级评论id（当此评论为子评论时存在）
          commentArticleId: ele.commentsArticleId, // 评论所在文章id
          commentContent: ele.commentsContent, // 评论内容
          commentTime: ele.commentsTime, // 评论时间
          commentArticleAuthorId: ele.commentsArticleAuthorId,
          commentReceiverUserId: ele.commentsReceiverUserId, // 评论接收者id
          commentReceiverUserName: ele.commentsReceiverUserName, // 评论接收者昵称
          commentUserId: ele.commentsUserId, // 此评论的用户id
          commentUserName: ele.commentsUserName, // 此评论的用户昵称
          commentUserProfilePhoto: ele.userProfilePhoto, // 此评论的用户头像
          commentIsReply: false, // 是否是回复状态（默认为否）
          userIsLogin: this.userIsLogin // 用户是否登录
        };
        // 判断是否存在子评论
        if (!isChild && ele.commentsChild && ele.commentsChild.length > 0) {
          // 存在子评论
          processCommentObject.commentChildList = this.formatCommentData(
            ele.commentsChild,
            true,
            index
          ); // 子评论列表
        } else if (parentIndex !== -1) {
          // 判断是否添加父级评论下标
          processCommentObject.commentParentIndex = parentIndex; // 父级评论下标
        }
        processData.push(processCommentObject);
      });
      return processData;
    },
    /**
     * 改变评论源数据的回复状态
     */
    changeCommentData: function(commentIsReply = false, parentIndex = -1, childIndex = -1) {
      if (childIndex !== -1) {
        // 子评论
        this.commentsList[parentIndex].commentChildList[childIndex].commentIsReply = commentIsReply;
      } else if (parentIndex !== -1) {
        // 父评论
        this.commentsList[parentIndex].commentIsReply = commentIsReply;
      }
    },
    /**
     * 获取评论数据
     */
    getCommentsList() {
      if (this.articleId === -1) {
        // 文章id为-1时不获取数据
        this.isMounted = true;
        return;
      }
      this.$api
        .commentsList({
          uri: { id: this.articleId },
          query: {
            page: 1, // 当前页数
            pageSize: this.paginationData.numberOfPages // 每页数量
          }
        })
        .then(res => {
          this.paginationData = this.formatPaginationData(res.paginationData);
          this.commentsList = this.formatCommentData(res.commentList);
        })
        .catch(() => {
          this.paginationData.currentPageNumber = this.paginationData.lastCurrentPageNumber;
        })
        .finally(() => {
          this.isMounted = true;
          this.closeLoading && this.closeLoading();
        });
    },
    /**
     * 格式化页数数据
     */
    formatPaginationData(data) {
      return {
        currentPageNumber: this.paginationData.currentPageNumber, // 当前页数
        lastCurrentPageNumber: this.paginationData.lastCurrentPageNumber, // 下一页之前保存当前页数
        numberOfPages: this.paginationData.numberOfPages, // 每页数量
        constNumberOfPages: this.paginationData.constNumberOfPages, // 每页数量
        totalPages: data.totalPage, // 总页数
        totalAmount: data.totalCounts // 总数量
      };
    },
    /**
     * 下一页
     */
    eventNextPage() {
      if (this.commentsList.length <= this.paginationData.totalAmount) {
        this.paginationData.lastCurrentPageNumber = this.paginationData.currentPageNumber;
        this.paginationData.currentPageNumber += 1;
        this.paginationData.numberOfPages =
          this.paginationData.currentPageNumber * this.paginationData.constNumberOfPages;

        this.closeLoading = this.$liang.mask.loading();
        this.getCommentsList();
      }
    },
    /**
     * 评论回复区域开启事件
     */
    eventCommentOpenReplyArea() {
      let commentIsOpenReplyArea = this.$store.state.comment.commentIsOpenReplyArea;
      let commentReplyIndexArr = this.$store.state.comment.commentReplyIndexArr;

      // 关闭上次开启的回复区域
      if (
        this.oldCommentIndexArr.length > 0 &&
        commentReplyIndexArr.join('') !== this.oldCommentIndexArr.join('')
      ) {
        this.changeCommentData(false, ...this.oldCommentIndexArr);
      }
      // 保存上一次的回复区域
      this.oldCommentIndexArr = commentReplyIndexArr;
      // 改变新的回复区域
      this.changeCommentData(commentIsOpenReplyArea, ...commentReplyIndexArr);
    },
    /**
     * 评论提交
     */
    eventCommentSubmit() {
      if (this.$store.state.comment.commentIsReadyToSubmit) {
        // 可以提交评论
        let commentType = this.$store.state.comment.commentReplayDataArr[0];
        let commentText = this.$store.state.comment.commentReplayDataArr[1];
        // 提交的评论数据
        let commentData = {
          commentsContent: commentText, // 评论内容
          commentsReceiverUserId: this.articleAuthorId
        };
        // 判断是几级评论
        if (commentType === 1) {
          // 二级评论 需要添加父级评论id
          let index = this.$store.state.comment.commentReplyIndexArr[0];
          commentData.commentsParentId = this.commentsList[index].commentId;
          commentData.commentsReceiverUserId = this.commentsList[index].commentUserId;
        }
        // 提交评论
        let loading = this.$liang.mask.loading();
        this.$api
          .commentAdd({ params: commentData, uri: { id: this.articleId } })
          .then(() => {
            // 关闭回复区域
            this.changeCommentData(false, ...this.oldCommentIndexArr);
            // 改变commentIsReadyToSubmit为false 并清空输入框
            this.$store.commit({
              type: 'comment/changeCommentSubmitStatus',
              replyArray: [],
              status: false
            });
            // 提示回复成功 重新获取数据
            this.$liang.mask.toast({
              content: '评论成功',
              showMask: true,
              complete: () => {
                // 获取评论数据
                this.getCommentsList();
              }
            });
          })
          .catch(() => {
            // 改变commentIsReadyToSubmit为false 保留上一次提交内容
            this.$store.commit({
              type: 'comment/changeCommentSubmitStatus',
              replyArray: this.$store.state.comment.commentReplayDataArr,
              status: false
            });
          })
          .finally(() => {
            loading();
          });
      }
    }
  },
  components: {
    'comment-detail': CommentDetail,
    'comment-input-area': CommentInputArea
  }
};
</script>

<style scoped lang="scss">
.comment-area {
  font-size: 16px;
  .comment-wrap {
    border-bottom: 1px solid rgba(34, 34, 34, 0.8);
    margin-top: 20px;
    overflow: hidden;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    /** 提示登录样式 */
    .comment-wrap-login {
      padding-bottom: 20px;
      a {
        display: inline-block;
        margin: 0 5px;
        color: inherit;
        .comment-wrap-login-icon {
          margin-right: 5px;
        }
      }
    }
    /** 输入样式 */
    .comment-wrap-input {
      padding-bottom: 20px;
    }
  }
  .comment-data {
    /** 评论列表 */
    .comment-list {
      margin-top: 20px;
      /** 评论内容 */
      .comment-list-item {
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(34, 34, 34, 0.8);
        /** 子评论列表容器 */
        .comment-child-list {
          padding-left: 70px;
        }
      }
    }
    /** 查看更多 */
    .comment-load-more {
      cursor: pointer;
      display: inline-block;
      .comment-load-more-text {
        margin-right: 5px;
      }
      a {
        color: inherit;
      }
    }
    /** 只有这些了 */
    .comment-no-more {
      display: inline-block;
      cursor: not-allowed;
      .comment-no-more-text {
        margin-right: 5px;
      }
    }
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .comment-area {
    .comment-data {
      .comment-list {
        .comment-list-item {
          .comment-child-list {
            padding-left: 55px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .comment-area {
    .comment-data {
      .comment-list {
        .comment-list-item {
          .comment-child-list {
            padding-left: 40px;
          }
        }
      }
    }
  }
}
</style>
