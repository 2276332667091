<template>
  <base-page-frame>
    <div class="user-visitor">
      <!-- 个人中心 -->
      <base-wrap>
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <user-background
            class="user-visitor-background"
            v-bind:name="userData.userName"
            v-bind:image="userData.userProfilePhoto"
            v-bind:background="userData.userBackground"
          />
        </div>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
import UserBackground from '../components/UserComponents/UserBackground';

export default {
  name: 'PageVisitor',
  data() {
    return {
      isMounted: false,
      /**
       * 用户数据
       */
      userData: {
        userName: '', // 用户名
        userBackground: '', // 用户背景图片
        userProfilePhoto: '' // 用户头像
      }
    };
  },
  mounted() {
    /**
     * 获取用户数据
     */
    this.getUserInfo();
  },
  methods: {
    /**
     * 获取用户信息
     */
    getUserInfo() {
      const userId = this.$route.params.userId;
      if (!userId) {
        // userId 不存在
        return this.$router.replace('NotFound');
      }

      this.$api
        .getVisitorUserInfo({ uri: { id: userId } })
        .then(res => {
          this.formatUserData(res);
        })
        .finally(() => {
          this.isMounted = true;
        });
    },
    /**
     * 格式化用户数据
     */
    formatUserData(data) {
      this.userData = {
        userName: data.userName, // 用户名
        userBackground: data.userBackground, // 用户背景图片
        userProfilePhoto: data.userProfilePhoto // 用户头像
      };
    }
  },
  components: {
    'user-background': UserBackground
  }
};
</script>

<style scoped lang="scss">
.user-visitor {
  padding-top: 20px;
  .user-visitor-background {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
}
</style>
