<template>
  <base-page-frame>
    <div class="index-wrap">
      <!-- 没有文章提示 -->
      <base-wrap v-if="paginationData.totalAmount <= 0">
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <p class="index-wrap-no-article-icon">
            <base-icon icon="bird" />
          </p>
          <p>这个人很懒，还没有写文章。</p>
        </div>
      </base-wrap>
      <!-- 文章展示 -->
      <template v-else>
        <template v-for="articleItem in articleList">
          <base-wrap v-bind:key="articleItem.articleId" class="index-wrap-article-item">
            <!-- 文章标题 -->
            <h1 class="article-title">
              <router-link class="animation" v-bind:to="`/article/${articleItem.articleId}`">{{
                articleItem.articleTitle
              }}</router-link>
            </h1>
            <!-- 文章信息 -->
            <div class="article-infomation">
              <!-- 文章作者 -->
              <base-icon class="article-author" icon="user">{{
                articleItem.articleAuthor
              }}</base-icon>
              <!-- 文章创作时间 -->
              <base-icon class="article-create-time" icon="calendar">{{
                articleItem.articleCreateTime
              }}</base-icon>
              <!-- 文章评论数量 -->
              <base-icon class="article-number-of-comments" icon="chat">{{
                articleItem.articleCommentsNumber
              }}</base-icon>
            </div>
            <!-- 文章banner图 -->
            <div v-if="articleItem.articleBanner" class="article-banner">
              <img
                v-bind:src="`${$store.getters['base/STATIC_HOST']}${articleItem.articleBanner}`"
                alt
              />
            </div>
            <!-- 文章描述 -->
            <div class="article-description">
              {{ articleItem.articleDescription }}
            </div>
            <!-- 阅读全文链接 -->
            <div class="article-reading-more">
              <router-link class="animation" v-bind:to="`/article/${articleItem.articleId}`"
                ><span>阅读全文</span><base-icon icon="link" class="article-reading-more-icon" />
              </router-link>
            </div>
            <!-- 文章分类 -->
            <div class="article-category">
              <base-icon icon="folder-open" class="article-category-icon" />文章分类:
              <router-link class="animation" :to="`/category/${articleItem.articleCategory}`">{{
                articleItem.articleCategory
              }}</router-link>
            </div>
          </base-wrap>
        </template>
        <!-- 只有这些了 -->
        <div v-if="articleList.length >= paginationData.totalAmount" class="index-no-more">
          <span class="index-no-more-text">只有这些了</span><base-icon icon="forbid" />
        </div>
        <!-- 查看更多 -->
        <div v-else class="index-load-more">
          <a v-on:click="eventNextPage" href="javascript:void(0)" class="animation"
            ><span class="index-load-more-text">查看更多</span><base-icon icon="arrow-down" />
          </a>
        </div>
      </template>
    </div>
  </base-page-frame>
</template>

<script>
/**
 * 首页 和 文章分类详情
 */
export default {
  name: 'PageIndex',
  data() {
    return {
      /**
       * 页面是否首次加载完成
       */
      isMounted: false,
      closeLoading: null,
      /**
       * 页码相关
       */
      paginationData: {
        currentPageNumber: 1, // 当前页数
        constNumberOfPages: 10, // 固定每页数量 不更改
        lastCurrentPageNumber: 1, // 下一页之前保存当前页数
        numberOfPages: 10, // 每页数量
        totalPages: 0, // 总页数
        totalAmount: 0 // 总数量
      },
      /**
       * 文章列表
       */
      articleList: []
    };
  },
  mounted() {
    this.getArticleList();
  },
  methods: {
    /**
     * 获取数据
     */
    async getArticleList() {
      const data = {
        query: {
          page: 1, // 当前页数
          pageSize: this.paginationData.numberOfPages // 每页数量
        }
      };
      const category = this.$route.params.category;
      let res = {};

      try {
        // 兼容文章分类详情
        if (category || String(category) === '0') {
          data.uri = category;
          // 分类
          res = await this.$api.articleCatagoryDetail(data);
        } else {
          // 首页
          res = await this.$api.articlesList(data);
        }

        this.articleList = this.formatArticleData(res.articleList);
        this.paginationData = this.formatPaginationData(res.paginationData);
      } catch (e) {
        this.paginationData.currentPageNumber = this.paginationData.lastCurrentPageNumber;
      } finally {
        this.isMounted = true;
        this.closeLoading && this.closeLoading();
      }
    },
    /**
     * 格式化文章列表数据
     */
    formatArticleData(list) {
      let formattedData = [];
      list.forEach(ele => {
        formattedData.push({
          articleTitle: ele.articleTitle, // 文章标题
          articleAuthor: ele.articleAuthor, // 文章作者
          articleId: ele.articleId, // 文章id
          articleCreateTime: ele.articleCreateTime, // 文章创建时间
          articleCommentsNumber: ele.articleCommentLength, // 文章评论数量
          articleDescription: ele.articleDescribe, // 文章描述
          articleCategory: ele.articleType, // 文章类型
          articleBanner: ele.articleBanner // 文章banner图
        });
      });
      return formattedData;
    },
    /**
     * 格式化页数数据
     */
    formatPaginationData(data) {
      return {
        currentPageNumber: this.paginationData.currentPageNumber, // 当前页数
        lastCurrentPageNumber: this.paginationData.lastCurrentPageNumber, // 下一页之前保存当前页数
        numberOfPages: this.paginationData.numberOfPages, // 每页数量
        constNumberOfPages: this.paginationData.constNumberOfPages, // 每页数量
        totalPages: data.totalPage, // 总页数
        totalAmount: data.totalCounts // 总数量
      };
    },
    /**
     * 下一页
     */
    eventNextPage() {
      if (this.articleList.length <= this.paginationData.totalAmount) {
        this.paginationData.lastCurrentPageNumber = this.paginationData.currentPageNumber;
        this.paginationData.currentPageNumber += 1;
        this.paginationData.numberOfPages =
          this.paginationData.currentPageNumber * this.paginationData.constNumberOfPages;

        this.closeLoading = this.$liang.mask.loading();
        this.getArticleList();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.index-wrap {
  text-align: center;
  padding-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  /** 文章展示样式 */
  .index-wrap-article-item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    /**文章标题 */
    .article-title {
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    a {
      display: inline-block;
      color: rgba(0, 0, 0, 0.9);
    }
    /**文章信息 */
    .article-infomation {
      font-size: 14px;
      padding-bottom: 10px;
      line-height: 1.5;
      border-bottom: 1px solid rgba(34, 34, 34, 0.8);
      .article-author,
      .article-create-time,
      .article-number-of-comments {
        margin-right: 15px;
        &::before {
          margin-right: 5px;
        }
      }
    }
    /**文章banner图 */
    .article-banner {
      margin-top: 10px;
      img {
        width: 100%;
        border-radius: 3px;
        max-height: 500px;
        object-fit: cover;
      }
    }
    /** 文章描述 */
    .article-description {
      font-size: 15px;
      text-align: left;
      line-height: 2;
      margin-top: 20px;
    }
    /** 阅读原文 */
    .article-reading-more {
      margin-bottom: 20px;
      margin-top: 20px;
      .article-reading-more-icon {
        margin-left: 5px;
      }
    }
    /** 文章分类 */
    .article-category {
      text-align: left;
      border-top: 1px solid rgba(34, 34, 34, 0.8);
      padding-top: 10px;
      font-size: 15px;
      .article-category-icon {
        margin-right: 5px;
      }
    }
  }
  /** 查看更多 */
  .index-load-more {
    color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
    display: inline-block;
    .index-load-more-text {
      margin-right: 5px;
    }
    a {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  /** 只有这些了 */
  .index-no-more {
    color: rgba(255, 255, 255, 0.9);
    display: inline-block;
    cursor: not-allowed;
    .index-no-more-text {
      margin-right: 5px;
    }
  }
  /** 没有文章的提示 */
  .index-wrap-no-article-icon {
    font-size: 50px;
    margin-bottom: 20px;
  }
}
</style>
