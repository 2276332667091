import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Mask from '@liang/vue2mask';
import Tools from './utils';
import Api from './interface';

import './assets/css/reset.scss'; // 引入重置样式
import BasePageFrame from './components/BaseComponents/BasePageFrame'; // 基础页面组件
import BaseIcon from './components/BaseComponents/BaseIcon/BaseIcon'; // 基础图标组件
import BaseWrap from './components/BaseComponents/BaseWrap'; // 基础容器组件
import BaseParseHTML from './components/BaseComponents/BaseParseHTML'; // 基础解析HTML组件
import BaseButton from './components/BaseComponents/BaseForm/BaseButton'; // 基础按钮组件
import BaseInput from './components/BaseComponents/BaseForm/BaseInput'; // 基础输入框组件
import BaseForm from './components/BaseComponents/BaseForm/BaseForm'; // 基础表单组件
import BaseUploadImage from './components/BaseComponents/BaseForm/BaseUploadImage'; // 基础上传图片组件
import BaseCheckBox from './components/BaseComponents/BaseForm/BaseCheckBox'; // 基础复选框组件
import BaseSwitch from './components/BaseComponents/BaseForm/BaseSwitch'; // 基础开关组件

Vue.use(Mask);
Vue.use(Tools);
Vue.use(Api);

Vue.component('base-page-frame', BasePageFrame);
Vue.component('base-icon', BaseIcon);
Vue.component('base-wrap', BaseWrap);
Vue.component('base-parse-html', BaseParseHTML);
Vue.component('base-button', BaseButton);
Vue.component('base-input', BaseInput);
Vue.component('base-form', BaseForm);
Vue.component('base-upload-image', BaseUploadImage);
Vue.component('base-check-box', BaseCheckBox);
Vue.component('base-switch', BaseSwitch);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
