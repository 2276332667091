<template>
  <form @submit.prevent="eventFormSubmit"><slot></slot></form>
</template>

<script>
export default {
  name: 'BaseForm',
  provide() {
    //  [不懂的可以看看](https://cn.vuejs.org/v2/api/#provide-inject)
    return {
      form: this
    };
  },
  props: {
    rules: {
      type: Object,
      required: false
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: [] // 储存当前所有的注册的子组件实例
    };
  },
  created() {
    // 保存生成的子组件实例
    this.$on('on-form-item-add', item => {
      if (item.prop) {
        this.fields.push(item);
      }
    });
    // 删除生成的子组件实例
    this.$on('on-form-item-remove', item => {
      if (item.prop) {
        // 如果当前没有prop的话表示当前不要进行删除（因为没有注入）
        this.fields.splice(this.fields.indexOf(item), 1);
      }
    });
  },
  methods: {
    /**
     * 表单提交
     */
    eventFormSubmit() {
      let count = 0; // 检测的数量
      let valid = true; // 是否验证通过
      /**
       * 所有子组件验证
       */
      this.fields.forEach(field => {
        if (field.validation('')) {
          // 有错误
          valid = false;
        }
        // 通过当前检查完所有的form-item的时候才会调用
        if (++count === this.fields.length) {
          this.$emit('base-submit', { valid: valid });
        }
      });
    },
    /**
     * 获取指定子组件验证状态
     */
    getCurrentValidationState(prop, callback = () => {}) {
      this.fields.forEach(field => {
        if (prop === field.prop) {
          let isError = !field.validation('');
          callback(isError);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
