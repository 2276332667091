<template>
  <div class="base-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseWrap'
};
</script>

<style scoped lang="scss">
/** 基础样式 */
.base-wrap {
  background: rgba(255, 255, 255, 0.8);
  max-width: 1170px;
  margin: 0 auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 20px;
  box-sizing: border-box;
}
/** 媒体查询 */
@media only screen and (max-width: 1170px) {
  .base-wrap {
    width: 95%;
  }
}
</style>
