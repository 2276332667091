<template>
  <div class="base-switch">
    <input
      type="checkbox"
      v-bind:id="name"
      v-on:change="eventSwitchChange"
      v-bind:checked="checked ? 'checked' : ''"
    /><label v-bind:for="name"></label>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  /**
   * 自定义 model (双向绑定)
   */
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    name: {
      type: String,
      required: true,
      default: 'switch'
    },
    checked: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  methods: {
    /**
     * 开关切换
     */
    eventSwitchChange(e) {
      let target = e.target;
      this.$emit('change', target.checked);
    }
  }
};
</script>

<style scoped lang="scss">
.base-switch {
  width: 55px;
  height: 30px;
  vertical-align: middle;
  position: relative;
  float: right;
}

.base-switch label {
  display: block;
  position: absolute;
  border-radius: 50px;
  width: 55px;
  height: 30px;
  transition: all 0.3s;
  border: 1px solid #9b9b9b;
  box-sizing: border-box;
  overflow: hidden;
}

.base-switch label:before {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #ffffff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0px 0px 5px 2px #9b9b9b;
}

.base-switch label:after {
  content: 'O';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  right: 2px;
  line-height: 24px;
  text-align: center;
  color: #999;
}

.base-switch input {
  display: none;
}

.base-switch input:checked + label {
  background: #34bf49;
  border-color: #34bf49;
}

.base-switch input:checked + label:before {
  left: 27px;
  box-shadow: unset;
}

.base-switch input:checked + label:after {
  content: 'I';
  color: #ffffff;
  right: 27px;
}
</style>
