/*
 * @Author: CloverLiang
 * @Date: 2020-04-21 17:42:27
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2020-05-02 18:42:56
 * 工具类js(不安装在vue中)
 */

/**
 * 禁止页面滚动
 * @param {Boolean} isFixed 是否禁止滚动 true => 禁止 false => 恢复
 */
export const stopBodyScroll = isFixed => {
  let htmlEl = document.documentElement;
  let top = window.$maskTop || 0;
  if (isFixed) {
    top = window.scrollY;
    // 判断是否存在滚动条
    hasScrollbar() ? (htmlEl.style.overflowY = 'scroll') : '';
    htmlEl.style.position = 'fixed';
    htmlEl.style.left = 0;
    htmlEl.style.right = 0;
    htmlEl.style.top = -top + 'px';

    window.$maskTop = top;
  } else {
    // 判断是否存在滚动条
    hasScrollbar() ? (htmlEl.style.overflowY = '') : '';
    htmlEl.style.position = '';
    htmlEl.style.top = '';
    htmlEl.style.left = '';
    htmlEl.style.right = '';

    window.scrollTo(0, top); // 回到原先的top
  }
};

/**
 * 判断是否有滚动条
 */
export const hasScrollbar = () => {
  return document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight);
};

/**
 * 函数防抖
 * @param {Function} fun 需要防抖的函数
 * @param {Number} delay 时间
 */
export const debounce = (fun, delay) => {
  return function(...args) {
    clearTimeout(fun.timer);
    fun.timer = setTimeout(function() {
      fun.call(this, ...args);
    }, delay);
  };
};

/**
 * 函数节流
 * @param {Function} fun 需要防抖的函数
 * @param {Number} delay 时间
 */
export const throttle = (fun, delay) => {
  let _lastTime = null;

  return function(...args) {
    let _nowTime = +new Date();
    if (_nowTime - _lastTime > delay || !_lastTime) {
      fun(...args);
      _lastTime = _nowTime;
    }
  };
};

/**
 * 数组去重
 * @param {Array} arr 需要替换的数组
 * @param {Key}  key 需要检查的key
 */
export const arrayToHeavy = (arr, key) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (arr[i][key] === arr[j][key]) {
        //第一个等同于第二个，splice方法删除第二个
        arr.splice(j, 1);
        j--;
      }
    }
  }
  return arr;
};

/**
 * 提取对象指定属性
 */
export const pickObject = (obj, arr) => {
  return arr.reduce((iter, val) => (val in obj && (iter[val] = obj[val]), iter), {});
};

/**
 * 用户数据本地化读取
 */
export const getLocalUserData = () => {
  return {
    userToken: localStorage.getItem('userToken') || '',
    userName: localStorage.getItem('userName') || '',
    userProfilePhoto: localStorage.getItem('userProfilePhoto') || ''
  };
};

/**
 * 去除所有空白字符
 * @param {String} str 需要去除空白字符的字符串
 * @returns {String} 返回去除空白字符后的字符串
 */
export const removeBlankCharts = (str = '') => {
  return String(str).replace(/\s+/g, '');
};

/**
 * 用户数据本地化保存
 */
export const setLocalUserData = userData => {
  localStorage.setItem('userToken', userData.userToken || '');
  localStorage.setItem('userName', userData.userName || '');
  localStorage.setItem('userProfilePhoto', userData.userProfilePhoto || '');
};

/**
 * 验证邮件格式
 */
export const validateEmailPass = (value = '', callback) => {
  if (/\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(value)) {
    callback();
  } else {
    callback(new Error('邮件格式不正确'));
  }
};

/**
 * 验证昵称规则
 */
export const validateUserNamePass = (value = '', callback) => {
  let length = removeBlankCharts(value).length;
  if (length < 1) {
    callback(new Error('昵称长度应大于1'));
  } else if (length > 17) {
    callback(new Error('昵称长度应小于17'));
  } else {
    callback();
  }
};

/**
 * 验证网址规则
 */
export const validateURL = (value = '', callback) => {
  let URL = removeBlankCharts(value);
  if (
    URL === '' ||
    /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i.test(
      URL
    )
  ) {
    callback();
  } else {
    callback(new Error('网址格式不正确'));
  }
};

/**
 * 图片格式及大小验证
 * @param {Object} file input file对象
 * @param {Object} options { type:Array, size:Number } type: 允许的图片数组 size: 允许的图片大小 单位b
 * @returns {Object} { valid: Boolean, message: String } valid: 是否验证通过 message: 验证不通过的消息
 */
export const validateImage = (file = {}, options = {}) => {
  let isPass = false;
  const { type = [], size = 0 } = options;
  const { type: fileType = '', size: fileSize = 0 } = file;
  // 格式验证
  type.forEach(item => {
    if (fileType.toLowerCase().indexOf(item) !== -1) {
      isPass = true;
    }
  });
  if (!isPass) {
    // 未通过格式验证
    return { valid: isPass, message: 'type' };
  }

  // 大小验证
  isPass = fileSize <= size;
  if (!isPass) {
    // 未通过大小验证
    return { valid: isPass, message: 'size' };
  }
  return { valid: isPass, message: 'success' };
};

/**
 * 安装在vue中的工具插件
 */
const Tools = {
  stopBodyScroll,
  debounce,
  arrayToHeavy,
  pickObject
};

export default {
  install: function(Vue) {
    Vue.prototype.$tools = Tools;
  }
};
