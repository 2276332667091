<template>
  <base-page-frame>
    <div class="article-wrap">
      <!-- 文章展示 -->
      <base-wrap class="article-wrap-article-item">
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <!-- 文章banner图 -->
          <div v-if="articleData.articleBanner" class="article-banner">
            <img
              v-bind:src="`${$store.getters['base/STATIC_HOST']}${articleData.articleBanner}`"
              alt="文章banner图"
            />
          </div>
          <!-- 文章标题 -->
          <h1 class="article-title">{{ articleData.articleTitle }}</h1>
          <!-- 文章信息 -->
          <div class="article-infomation">
            <!-- 文章作者 -->
            <base-icon class="article-author" icon="user">{{
              articleData.articleAuthor
            }}</base-icon>
            <!-- 文章创作时间 -->
            <base-icon class="article-create-time" icon="calendar">{{
              articleData.articleCreateTime
            }}</base-icon>
            <!-- 文章评论数量 -->
            <base-icon class="article-number-of-comments" icon="chat">{{
              articleData.articleCommentsNumber
            }}</base-icon>
          </div>
          <!-- 文章内容 -->
          <div class="article-content">
            <base-parse-html v-bind:html-str="articleData.articleDeatil"></base-parse-html>
          </div>
          <!-- 文章更新时间 -->
          <div class="article-update-time">
            <base-icon icon="calendar" class="article-update-time-icon"></base-icon
            ><span>更新时间: {{ articleData.articleUpdateTime }}</span>
          </div>
          <!-- 文章分类 -->
          <div class="article-category">
            <base-icon icon="folder-open" class="article-category-icon"></base-icon
            ><span>文章分类: {{ articleData.articleCategory }}</span>
          </div>
        </div>
      </base-wrap>
      <!-- 评论模块 -->
      <base-wrap v-if="articleData.articleIsCanComment" class="comment-area">
        <comment-area
          v-bind:article-id="articleData.articleId"
          v-bind:article-author-id="articleData.articleAuthorId"
        />
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
// 文章详情
import CommentArea from '../components/CommentArea/CommentArea';
export default {
  name: 'PageArticleDetail',
  data() {
    return {
      isMounted: false, // 页面是否首次加载完成
      /**
       * 文章数据
       */
      articleData: {
        articleTitle: '', // 文章标题
        articleDeatil: '', // 文章内容
        articleAuthor: '', // 文章作者
        articleAuthorId: '', // 文章作者 id
        articleCreateTime: '', // 文章创建时间
        articleCategory: '', // 文章类型
        articleUpdateTime: '', // 文章更新时间
        articleId: -1, // 文章id
        articleIsAbout: false, // 文章是否是关于页文章
        articleCommentsNumber: -1, // 文章评论数量
        articleBanner: '', // 文章banner图
        articleIsCanComment: false // 文章是否可以评论
      }
    };
  },
  mounted() {
    this.getArticleDetail();
  },
  methods: {
    /**
     * 获取文章详细信息
     */
    getArticleDetail() {
      this.$api
        .articleDetail({ uri: { id: this.$route.params.articleId } })
        .then(res => {
          this.formatArticleData(res);
          // 是关于页文章转到关于页详情
          if (this.articleData.articleIsAbout) {
            return this.$router.replace({ path: `/about${this.$route.hash}` });
          }
        })
        .finally(() => {
          this.isMounted = true;
        });
    },
    /**
     * 格式化文章信息
     */
    formatArticleData(data) {
      this.articleData = {
        articleDeatil: data.articleContent, // 文章内容
        articleTitle: data.articleTitle, // 文章标题
        articleAuthor: data.articleAuthor, // 文章作者
        articleAuthorId: data.articleAuthorId, // 文章作者 id
        articleCreateTime: data.articleCreateTime, // 文章创建时间
        articleCategory: data.articleType, // 文章类型
        articleUpdateTime: data.articleUpdateTime, // 文章更新时间
        articleId: data.articleId, // 文章id
        articleIsAbout: data.articleIsAbout, // 文章是否是关于页文章
        articleCommentsNumber: data.articleCommentLength, // 文章评论数量
        articleIsCanComment: data.articleIsCanComment, // 文章是否可以评论
        articleBanner: data.articleBanner // 文章banner图
      };
    }
  },
  components: {
    'comment-area': CommentArea
  }
};
</script>

<style scoped lang="scss">
.article-wrap {
  text-align: center;
  padding-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  .article-wrap-mounted {
    margin-bottom: 20px;
  }
  /** 文章展示样式 */
  .article-wrap-article-item {
    margin-bottom: 20px;
    /**文章banner图 */
    .article-banner {
      margin: -20px;
      margin-bottom: 20px;
      img {
        width: 100%;
        max-height: 350px;
        object-fit: cover;
        border-radius: 3px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    /**文章标题 */
    .article-title {
      font-size: 30px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    /**文章信息 */
    .article-infomation {
      font-size: 14px;
      padding-bottom: 10px;
      line-height: 1.5;
      border-bottom: 1px solid rgba(34, 34, 34, 0.8);
      .article-author,
      .article-create-time,
      .article-number-of-comments {
        margin-right: 15px;
        &::before {
          margin-right: 5px;
        }
      }
    }
    /** 文章内容 */
    .article-content {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: left;
    }
    /** 文章更新时间 */
    .article-update-time {
      text-align: left;
      border-top: 1px solid rgba(34, 34, 34, 0.8);
      padding-top: 10px;
      font-size: 15px;
      .article-update-time-icon {
        margin-right: 5px;
      }
    }
    /** 文章分类 */
    .article-category {
      text-align: left;
      padding-top: 10px;
      font-size: 15px;
      .article-category-icon {
        margin-right: 5px;
      }
    }
  }
}
</style>
