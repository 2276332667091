/*
 * @Author: CloverLiang
 * @Date: 2020-04-04 12:36:27
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-05-24 16:22:54
 * restful 风格 axios 封装
 */
import Request from '@liang/restful-api';
import config from './config';
import store from '../store';
import router from '../router';
import Vue from 'vue';
const vue = new Vue();

// 统一错误处理
let errorHandler = (status, err) => {
  let message = err.message;

  if (err && err.response) {
    switch (err.response.status) {
      case 400:
        message = err.response?.data?.message || '请求错误';
        break;
      case 401:
        message = '请登录';
        store.commit('user/userLogout');
        router.push({
          name: 'PageLogin'
        });
        break;
      case 403:
        message = '拒绝访问';
        break;
      case 404:
        message = `没有数据: ${err.response.config.url}`;
        router.replace({
          name: 'NotFound'
        });
        break;
      case 408:
        message = '请求超时';
        break;
      case 500:
        message = '服务器内部错误';
        break;
      case 501:
        message = '服务未实现';
        break;
      case 502:
        message = '网关错误';
        break;
      case 503:
        message = '服务不可用';
        break;
      case 504:
        message = '网关超时';
        break;
      case 505:
        message = 'HTTP版本不受支持';
        break;
      default:
    }
  }
  // 请求超时
  if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
    message = '请求超时';
    // return service.request(originalRequest);//例如再重复请求一次
  }
  // 错误信息提示(记录)
  vue.$liang.mask.toast({
    content: `${message}`,
    duration: 3000
  });
  window.console.error(err.response?.data || err);
};

export default new Request({
  axios: config,
  request: [
    config => {
      // 设置token
      store.state.user.userToken
        ? (config.headers['Authorization'] = `Bearer ${store.state.user.userToken}`)
        : '';
      return config;
    },
    err => {
      errorHandler('请求错误', err);
      throw err;
    }
  ],
  response: [
    response => {
      let data;
      // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
      if (response.data == undefined) {
        data = response.request.responseText;
      } else {
        data = response.data;
      }

      return data;
    },
    err => {
      errorHandler('响应错误', err);
      throw err;
    }
  ]
});
