<template>
  <div class="user-notification" v-inline-loading="{ show: !isMounted, color: 'black' }">
    <ul v-if="notificationList.length > 0">
      <li
        class="user-notification-item"
        v-for="notification in notificationList"
        v-bind:key="notification.notificationTime"
      >
        <div class="user-notification-item-top">
          <h4 class="user-notification-item-title">
            {{ notification.notificationTitle }}
          </h4>
          <div class="user-notification-item-time">
            {{ notification.notificationTime }}
          </div>
        </div>
        <div class="user-notification-item-content">
          <base-parse-html v-bind:html-str="notification.notificationContent"></base-parse-html>
        </div>
      </li>
    </ul>
    <div v-else class="user-notification-no-data">你已到达了世界的尽头</div>
  </div>
</template>

<script>
export default {
  name: 'UserNotification',
  props: {
    originUserData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      isMounted: false,
      notificationList: []
    };
  },
  mounted() {
    this.getUserNotification();
  },
  methods: {
    /**
     * 获取数据
     */
    getUserNotification() {
      this.$api.getUserNotification().then(res => {
        this.isMounted = true;
        this.notificationList = this.formatUserNotification(res.notificationList);
      });
    },
    /**
     * 格式化数据
     */
    formatUserNotification(list) {
      let formattedList = [];
      list.forEach(item => {
        formattedList.push({
          notificationTitle: item.noticeTitle,
          notificationTime: item.noticeTime,
          notificationContent: item.noticeContent
        });
      });
      return formattedList;
    }
  }
};
</script>

<style scoped lang="scss">
.user-notification {
  .user-notification-item {
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .user-notification-item-top {
      padding-bottom: 10px;
      border-bottom: 1px solid #222;
      .user-notification-item-title {
        font-weight: bolder;
        display: inline-block;
      }
      .user-notification-item-time {
        font-size: 0.9em;
        color: #999;
        display: inline-block;
        margin-left: 20px;
        vertical-align: bottom;
      }
    }
    .user-notification-item-content {
      padding: 0 20px;
      padding-top: 10px;
    }
  }
  .user-notification-no-data {
    text-align: center;
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .user-notification {
    .user-notification-item {
      padding: 10px;
      .user-notification-item-content {
        padding: 0 10px;
        padding-top: 5px;
      }
    }
  }
}
</style>
