/*
 * @Author: CloverLiang
 * @Date: 2020-05-06 21:26:03
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-06 21:30:05
 * vue route 文件
 */

import PageIndex from '../../views/PageIndex';
import PageArticleDetail from '../../views/PageArticleDetail';
import PageLogin from '../../views/PageLogin';
import PageRegister from '../../views/PageRegister';
import PageForget from '../../views/PageForget';
import PageAbout from '../../views/PageAbout';
import PageLogout from '../../views/PageLogout';
import PageCategory from '../../views/PageCategory';
import PageUserCenter from '../../views/PageUserCenter';
import PageVisitor from '../../views/PageVisitor';
import Demo from '../../views/Demo';

const routes = [
  {
    path: '/',
    name: 'PageIndex',
    component: PageIndex
  },
  {
    path: '/category',
    name: 'PageCategory',
    component: PageCategory
  },
  {
    path: '/category/:category',
    name: 'PageCategoryDetail',
    component: PageIndex
  },
  {
    path: '/article/:articleId',
    name: 'PageArticleDetail',
    component: PageArticleDetail
  },
  {
    path: '/login',
    name: 'PageLogin',
    component: PageLogin
  },
  {
    path: '/register',
    name: 'PageRegister',
    component: PageRegister
  },
  {
    path: '/forget',
    name: 'PageForget',
    component: PageForget
  },
  {
    path: '/about',
    name: 'PageAbout',
    component: PageAbout
  },
  {
    path: '/logout',
    name: 'PageLogout',
    component: PageLogout
  },
  {
    path: '/center',
    name: 'PageUserCenter',
    component: PageUserCenter
  },
  {
    path: '/u/:userId',
    name: 'PageVisitor',
    component: PageVisitor
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo
  }
];

export default routes;
