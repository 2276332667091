<template>
  <ul class="user-information">
    <base-form v-bind:model="userData" v-bind:rules="userDataRules" v-on:base-submit="eventSubmit">
      <!-- 头像 -->
      <li class="user-information-item">
        <div class="user-information-item-info">头像：</div>
        <div class="user-information-item-box">
          <base-upload-image
            class="user-information-item-box-profile-photo"
            accept="image/jpeg,image/jpg,image/png"
            v-bind:before-upload="beforeUploadImage"
            v-bind:upload="uploadImage"
            v-model="userData.userProfilePhoto"
            prop="userProfilePhoto"
            name="userProfilePhoto"
          >
            <template v-slot:upload>
              <div
                class="user-information-item-box-profile-photo-image"
                v-bind:style="
                  `background-image:url(${$store.getters['base/STATIC_HOST']}${userData.userProfilePhoto})`
                "
              ></div>
            </template>
          </base-upload-image>
          <div class="user-information-item-box-image-notice">
            <span>JPG,PNG格式，大小不要超过5MB</span>
          </div>
        </div>
      </li>
      <!-- 背景 -->
      <li class="user-information-item">
        <div class="user-information-item-info">背景：</div>
        <div class="user-information-item-box">
          <base-upload-image
            class="user-information-item-box-background"
            accept="image/jpeg,image/jpg,image/png"
            v-bind:before-upload="beforeUploadImage"
            v-bind:upload="uploadImage"
            v-model="userData.userBackground"
            prop="userBackground"
            name="userBackground"
          >
            <template v-slot:upload>
              <div
                v-if="userData.userBackground"
                class="user-information-item-box-background-image"
                v-bind:style="
                  `background-image:url(${$store.getters['base/STATIC_HOST']}${userData.userBackground})`
                "
              ></div>
              <div v-else class="user-information-item-box-background-image-notice">
                <span>上传背景图</span>
              </div>
            </template>
          </base-upload-image>
          <div class="user-information-item-box-background-bottom">
            <div class="user-information-item-box-image-notice">
              <span>JPG,PNG格式，大小不要超过5MB</span>
            </div>
            <div
              v-on:click.stop="eventDeleteBackground"
              class="user-information-item-box-image-background-delete"
            >
              <span>删除</span>
            </div>
          </div>
        </div>
      </li>
      <!-- 昵称 -->
      <li class="user-information-item">
        <div class="user-information-item-info">昵称：</div>
        <div class="user-information-item-box">
          <div class="user-information-item-box-input">
            <base-input
              v-model="userData.userName"
              prop="userName"
              name="userName"
              placeholder="昵称"
            ></base-input>
          </div>
        </div>
      </li>
      <!-- 网站 -->
      <li class="user-information-item">
        <div class="user-information-item-info">网站：</div>
        <div class="user-information-item-box">
          <div class="user-information-item-box-input">
            <base-input
              v-model="userData.userWebsite"
              prop="userWebsite"
              name="userWebsite"
              placeholder="个人网站"
            ></base-input>
          </div>
        </div>
      </li>
      <!-- 接收站内信 -->
      <li class="user-information-item">
        <div class="user-information-item-info">站内信：</div>
        <div class="user-information-item-box">
          <base-switch name="notice" v-model="userData.userNoticeStatus"></base-switch>
        </div>
      </li>
      <!-- 接收邮件通知 -->
      <li class="user-information-item">
        <div class="user-information-item-info">邮件通知：</div>
        <div class="user-information-item-box">
          <base-switch v-model="userData.userEmailNoticeStatus" name="email"></base-switch>
        </div>
      </li>
      <!-- 保存 -->
      <li class="user-information-item">
        <base-button class="user-information-item-submit" type="submit">保存</base-button>
      </li>
    </base-form>
  </ul>
</template>

<script>
import { validateUserNamePass, validateURL, validateImage } from '../../utils';

export default {
  name: 'UserInformation',
  props: {
    originUserData: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      userData: {
        userName: '', // 用户名
        userBackground: '', // 用户背景图片
        userProfilePhoto: '', // 用户头像
        userWebsite: '', // 用户个人网站地址
        userNoticeStatus: false, // 用户是否接收通知消息
        userEmailNoticeStatus: false // 用户是否接收邮件通知消息
      },
      userDataRules: {
        userName: [
          { isRequired: true, trigger: 'blur' },
          { validator: validateUserNamePass, trigger: 'blur' }
        ],
        userWebsite: [{ validator: validateURL, trigger: 'blur' }],
        userProfilePhoto: [{ isRequired: true, trigger: 'blur' }]
      },
      closeLoading: null
    };
  },
  mounted() {
    this.updateUserData(this.originUserData);
  },
  watch: {
    /**
     * 确保本地userData保持最新
     */
    originUserData: function(n) {
      this.updateUserData(n);
    }
  },
  methods: {
    /**
     * 提交事件
     */
    eventSubmit(canSubmit) {
      if (canSubmit.valid) {
        this.closeLoading = this.$liang.mask.loading();
        this.$api
          .updateUserInfo({
            params: {
              ...this.userData,
              userEmailNoticeFlag: Number(this.userData.userEmailNoticeStatus),
              userNoticeFlag: Number(this.userData.userNoticeStatus)
            }
          })
          .then(() => {
            // 数据更新成功
            this.$liang.mask.toast({
              content: '更新成功',
              complete: () => {
                this.$emit('user-info-update');
              }
            });
          })
          .finally(() => {
            this.closeLoading();
          });
      }
    },
    /**
     * 图片上传之前
     */
    beforeUploadImage(file) {
      // 验证图片格式和大小
      let isPass = validateImage(file, {
        type: ['jpg', 'jpeg', 'png'],
        size: 1024 * 1000 * 5 // 5mb
      });
      if (!isPass.valid) {
        let message = '请选择正确的文件格式';
        switch (isPass.message) {
          case 'type':
            break;
          case 'size':
            message = '请选择小于5MB的文件';
            break;
        }
        this.$liang.mask.toast({ content: message });
      }
      return isPass.valid;
    },
    /**
     * 图片上传
     */
    uploadImage(file, next) {
      this.closeLoading = this.$liang.mask.loading();
      let uploadData = new FormData();
      uploadData.append('file', file);
      this.$api
        .uploadImage({ params: uploadData })
        .then(res => {
          next(res[0].name);
        })
        .catch(() => {
          next();
        })
        .finally(() => {
          this.closeLoading();
        });
    },
    /**
     * 删除用户背景
     */
    eventDeleteBackground() {
      this.userData.userBackground = '';
    },
    /**
     * 更新用户数据
     */
    updateUserData(originUserData) {
      if (originUserData.userName && originUserData.userName !== '') {
        let userData = this.$tools.pickObject(originUserData, [
          'userName',
          'userBackground',
          'userProfilePhoto',
          'userWebsite',
          'userNoticeStatus',
          'userEmailNoticeStatus'
        ]);
        this.userData = userData;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-information {
  .user-information-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .user-information-item-info {
      flex-shrink: 0;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .user-information-item-box {
      width: 100%;
    }
    /** 头像 */
    .user-information-item-box-profile-photo {
      display: inline-block;
      .user-information-item-box-profile-photo-image {
        width: 100px;
        height: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #222;
      }
    }
    .user-information-item-box-image-notice {
      font-size: 0.8em;
      margin-top: 15px;
    }
    /** 背景 */
    .user-information-item-box-background {
      position: relative;
      width: 100%;
      height: 250px;
      .user-information-item-box-background-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #222;
      }
      .user-information-item-box-background-image-notice {
        border: 1px solid #222;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    /** 删除按钮 */
    .user-information-item-box-background-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .user-information-item-box-image-background-delete {
        cursor: pointer;
        font-size: 0.8em;
      }
    }

    .user-information-item-box-input {
      border-bottom: 1px solid #222;
    }
    .user-information-item-submit {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .user-information {
    .user-information-item {
      .user-information-item-info {
        padding-right: 0;
      }
      .user-information-item-box-background {
        height: 180px;
      }
    }
  }
}
</style>
