<template>
  <div class="comment-wrap-textarea" v-bind:class="isReply ? 'is-reply' : ''">
    <form @submit.prevent="eventSubmitComment">
      <div class="comment-wrap-textarea-box">
        <textarea v-bind:placeholder="placeholderStr" name="textarea" v-model="textarea"></textarea>
      </div>
      <div class="comment-wrap-textarea-bottom">
        <div class="comment-wrap-textarea-bottom-left">
          <base-icon icon="markdown" class="comment-wrap-textarea-bottom-left-icon" /><span
            >支持MarkDown</span
          >
        </div>
        <div class="comment-wrap-textarea-bottom-right">
          <base-button type="submit">{{ isReply ? '回复' : '发表' }}</base-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'CommentInputArea',
  props: {
    // placeholder占位字符
    placeholderStr: {
      type: String,
      default: '说点什么吧...',
      required: false
    },
    // 是否是二级评论（回复）
    isReply: {
      type: Boolean,
      default: false, // false => 表示一级评论 true => 表示二级评论（回复）
      required: false
    }
  },
  data() {
    return {
      textarea: ''
    };
  },
  watch: {
    /**
     * 评论提交成功后清空输入框
     */
    '$store.state.comment.commentReplayDataArr': function() {
      if (this.$store.state.comment.commentReplayDataArr.length <= 0) {
        this.textarea = '';
      }
    }
  },
  methods: {
    /**
     * 提交事件
     */
    eventSubmitComment() {
      if (!this.textarea) {
        // 评论为空
        return this.$liang.mask.toast({ content: '总得说点什么吧' });
      }
      // 改变评论提交状态
      this.$store.commit({
        type: 'comment/changeCommentSubmitStatus',
        replyArray: this.isReply ? [1, this.textarea] : [0, this.textarea],
        status: true
      });
    }
  }
};
</script>

<style scoped lang="scss">
/** 输入框样式 */
.comment-wrap-textarea {
  text-align: left;
  .comment-wrap-textarea-box {
    border: 1px solid #000;
    padding: 5px 5px 5px 10px;
    border-radius: 3px;
    textarea {
      width: 100%;
      box-sizing: border-box;
      min-height: 100px;
      text-align: left;
      word-break: break-all;
      /** 解决某些安卓浏览器上textarea有默认背景颜色问题 */
      background: none;
    }
  }
  .comment-wrap-textarea-bottom {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .comment-wrap-textarea-bottom-left {
      font-size: 90%;
      color: #666;
      .comment-wrap-textarea-bottom-left-icon {
        margin-right: 5px;
      }
    }
  }
}
</style>
