<template>
  <base-page-frame>
    <div class="error-content">
      <base-wrap>
        <p class="error-content-icon">
          <base-icon icon="bug" />
        </p>
        <p class="error-content-number">404</p>
        <p>下面？ 下面没有了！</p>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
export default {
  name: 'PageError'
};
</script>

<style scoped lang="scss">
.error-content {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  .error-content-icon {
    font-size: 50px;
  }
  .error-content-number {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
