<template>
  <label class="checkbox-wrap">
    <input type="checkbox" :checked="checked" @change="$emit('change', $event.target.checked)" />
    <div class="checkbox-border">
      <base-icon icon="check" class="checkbox-border-symbol" />
    </div>
    <div class="checkbox-slot">
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseCheckBox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.checkbox-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    display: none;
    &:checked + .checkbox-border {
      .checkbox-border-symbol {
        display: block;
      }
    }
  }
  .checkbox-border {
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    display: inline-block;
    .checkbox-border-symbol {
      display: none;
    }
  }
  .checkbox-slot {
    margin-left: 10px;
  }
}
</style>
