<template>
  <div class="base-input-wrap">
    <slot name="first"></slot>
    <div
      class="base-input-wrap-input"
      v-bind:class="isShowError ? 'base-input-wrap-input-error' : ''"
    >
      <input
        ref="input"
        v-bind="$attrs"
        placeholder=""
        v-bind:value="value"
        v-on:blur="eventInputBlur"
        v-on:input="eventInputInput"
        v-on:change="eventInputChange"
      />
      <label class="base-input-wrap-input-placeholder">{{ $attrs.placeholder }}</label>
      <div class="base-input-wrap-input-error-message">
        <span>{{ errorMessage }}</span>
      </div>
    </div>
    <slot name="last"></slot>
  </div>
</template>

<script>
import Emitter from './emitter';
export default {
  name: 'BaseInput',
  inject: ['form'],
  inheritAttrs: false, // 禁用 Attribute 继承
  /**
   * 自定义 model (双向绑定)
   */
  model: {
    prop: 'value',
    event: 'input'
  },
  /** 混入 */
  mixins: [Emitter],
  props: {
    /** 规则验证用的属性名 */
    prop: {
      type: String,
      required: false,
      default: ''
    },
    /** model 属性 */
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isShowError: false,
      errorMessage: ''
    };
  },
  mounted() {
    /** 在 BaseForm 组件列表中注册该组件 */
    this.dispatch('BaseForm', 'on-form-item-add', this);
    /**
     * 解决初始化时，如果value不为空，输入状态不改变问题
     */
    this.setInputInputed(this.$refs['input']);
  },
  destroyed() {
    /** 在 BaseForm 组件列表中移除该组件 */
    this.dispatch('BaseForm', 'on-form-item-remove', this);
  },
  watch: {
    /**
     * 解决 ios safari 下有几率出现的输入状态不改变
     */
    value: function() {
      this.setInputInputed(this.$refs['input']);
      // /**
      //  * 解决 ios safari 下自动填充时不触发验证问题
      //  */
      // this.validation("blur");
    }
  },
  methods: {
    /**
     * 失焦事件
     */
    eventInputBlur(el) {
      /**
       * 使输入状态改变总可以触发
       */
      this.setInputInputed(el.target);
      // 验证
      this.validation('blur');
    },
    /**
     * 输入事件
     * 自定义 model (双向绑定)
     */
    eventInputInput(el) {
      this.$emit('input', el.target.value);
      /**
       * 使输入状态改变总可以触发
       */
      this.setInputInputed(el.target);
      // 验证
      this.validation('input');
    },
    /**
     * 改变事件
     */
    eventInputChange(el) {
      /**
       * 使输入状态改变总可以触发
       */
      this.setInputInputed(el.target);
      // 验证
      this.validation('change');
    },
    /**
     * 设置输入状态
     */
    setInputInputed(el) {
      el.className = this.value.length > 0 ? 'inputed' : '';
    }
  }
};
</script>

<style scoped lang="scss">
.base-input-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .base-input-wrap-input {
    width: 100%;
    margin: 0 10px;
    position: relative;
    input {
      width: 100%;
      padding: 10px 5px;
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      &.inputed + .base-input-wrap-input-placeholder,
      &:focus + .base-input-wrap-input-placeholder {
        transform: translateY(-150%) scale(0.8);
        color: #333;
        z-index: 3;
      }
    }
    .base-input-wrap-input-placeholder {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: #666;
      transition: transform 0.3s;
      transform-origin: left center;
      z-index: 1;
    }
    .base-input-wrap-input-error-message {
      color: #e91e63;
      position: absolute;
      bottom: -100%;
      transform: translateY(-100%);
      font-size: 0.9em;
      white-space: nowrap;
      z-index: 4;
      display: none;
    }
    &.base-input-wrap-input-error {
      .base-input-wrap-input-placeholder {
        color: #e91e63 !important;
      }
      .base-input-wrap-input-error-message {
        display: block;
      }
    }
  }
}
</style>
