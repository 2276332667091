/*
 * @Author: CloverLiang
 * @Date: 2020-04-21 18:48:34
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-05 16:15:13
 * vue router 路由
 */
import Vue from 'vue';
import VueRouter from 'vue-router';

import { getLocalUserData } from '../utils';
import store from '../store';

import routes from './routes';
import PageError from '../views/PageError';

/**
 * 路由黑名单 需要登录
 */
const blackList = ['/center'];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    {
      path: '/404',
      name: 'NotFound',
      component: PageError
    },
    {
      path: '*',
      name: 'PageError',
      component: PageError
    }
  ]
});

/**
 * vue router hook
 */
router.beforeEach((to, from, next) => {
  let localUserData = getLocalUserData();
  if (localUserData.userToken) {
    /**
     * 本地token存在
     */
    if (localUserData.userToken !== store.state.user.userToken) {
      /**
       * 本地保存的用户数据与vuex中的数据保持同步
       */
      store.commit({
        type: 'user/userLogin',
        userToken: localUserData.userToken,
        userName: localUserData.userName,
        userProfilePhoto: localUserData.userProfilePhoto
      });
    }

    next();
  } else {
    /**
     * 本地token不存在
     */
    if (blackList.indexOf(to.path.replace(/\/*$/, '')) !== -1) {
      /**
       * 在黑名单中
       */
      next({
        path: '/login',
        query: {
          redirect: to.path
        }
      });
    } else {
      next();
    }
  }
});

export default router;
