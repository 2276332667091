<template>
  <base-page-frame>
    <div class="form-wrap">
      <base-wrap>
        <base-form v-bind:model="form" v-bind:rules="rules" v-on:base-submit="eventSubmit">
          <div class="form-wrap-title">欢迎回来</div>
          <!-- 邮箱 -->
          <base-input
            class="input-wrap"
            type="text"
            placeholder="邮箱"
            v-model="form.email"
            name="email"
            prop="email"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="mail" />
            </template>
          </base-input>
          <!-- 密码 -->
          <base-input
            class="input-wrap"
            type="password"
            placeholder="密码"
            v-model="form.password"
            name="password"
            prop="password"
          >
            <template v-slot:first>
              <base-icon class="input-wrap-icon" icon="lock" />
            </template>
          </base-input>
          <!-- 登录按钮 -->
          <base-button type="submit">登录</base-button>
        </base-form>
        <!-- 底部提示 -->
        <div class="form-wrap-bottom">
          <div class="form-wrap-bottom-right">
            <router-link class="animation" to="/forget">忘记密码？</router-link>
          </div>
          <div class="form-wrap-bottom-left">
            <span>没有账号？</span>
            <router-link class="animation" to="/register">立即注册</router-link>
          </div>
        </div>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
import md5 from 'md5';
import { validateEmailPass } from '../utils';

export default {
  name: 'PageLogin',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          { isRequired: true, trigger: 'blur' },
          { validator: validateEmailPass, trigger: 'blur' }
        ],
        password: [{ isRequired: true, trigger: 'blur' }]
      }
    };
  },
  methods: {
    /**
     * 表单提交
     */
    eventSubmit(canSubmit) {
      if (canSubmit.valid) {
        let closeLoading = this.$liang.mask.loading();
        this.$api
          .userLogin({
            params: {
              userAccount: this.form.email,
              userPassword: md5(this.form.password)
            }
          })
          .then(res => {
            // 保存token到vuex
            this.$store.commit({
              type: 'user/userLogin',
              userToken: res.userToken,
              userName: res.userName,
              userProfilePhoto: res.userProfilePhoto
            });
            // 提示登录成功
            this.$liang.mask.toast({
              content: '欢迎回来',
              showMask: true,
              complete: () => {
                // 不跳转白名单
                let whiteList = ['/register', '/login', '/forget'];
                let path = decodeURIComponent(this.$route.query.redirect || '/');
                whiteList.forEach(item => {
                  item.indexOf(path) !== -1 ? (path = '/') : '';
                });
                this.$router.replace({
                  path: path
                });
              }
            });
          })
          .finally(() => {
            closeLoading();
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.form-wrap {
  padding-top: 20px;
  .form-wrap-title {
    font-size: 1.5em;
    font-weight: 900;
    margin-bottom: 30px;
  }
  .input-wrap {
    border-bottom: 1px solid #999;
    margin-bottom: 30px;
    .input-wrap-icon {
      font-size: 1.5em;
    }
  }
  .form-wrap-bottom {
    margin-top: 50px;
    font-size: 0.8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  a {
    color: inherit;
  }
}
</style>
