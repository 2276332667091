<template>
  <div class="markdown-body">
    <div v-html="htmlStr" v-auto-add-line-number-and-add-code-style></div>
  </div>
</template>

<script>
// 代码高亮模块
import hljs from 'highlight.js/lib/highlight';
hljs.registerLanguage('markdown', require('highlight.js/lib/languages/markdown'));
hljs.registerLanguage('css', require('highlight.js/lib/languages/css'));
hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));
hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
hljs.registerLanguage('http', require('highlight.js/lib/languages/http'));
hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
hljs.registerLanguage('nginx', require('highlight.js/lib/languages/nginx'));
hljs.registerLanguage('sql', require('highlight.js/lib/languages/sql'));
// 导入代码高亮样式
import '../../assets/css/highlight.scss';
// 导入markdown样式
import '../../assets/css/markdown.scss';
export default {
  name: 'BaseParseHTML',
  props: {
    htmlStr: {
      type: String,
      default: ''
    }
  },
  // 自定义指令
  directives: {
    // 自动添加行号并添加代码样式
    'auto-add-line-number-and-add-code-style': function(el) {
      let blocks = el.querySelectorAll('pre code');
      blocks.forEach(block => {
        hljs.highlightBlock(block);
        // 添加行号
        let text = block.innerText;
        let lineNumber = text.split('\n').length - 1 || 1;
        let lineNumberStr = ``;
        for (let i = 0; i < lineNumber; i++) {
          lineNumberStr += `<span></span>`;
        }
        let lineDiv = document.createElement('div');
        lineDiv.className = 'markdown-line-number';
        lineDiv.innerHTML = lineNumberStr;
        block.appendChild(lineDiv);
      });
    }
  }
};
</script>
