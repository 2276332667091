/*
 * @Author: CloverLiang
 * @Date: 2020-04-21 17:52:10
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-05 16:39:44
 * vuex user状态管理
 */
import { setLocalUserData } from '../../utils';

const moduleUser = {
  namespaced: true,
  state: {
    userToken: '', // 用户token
    userName: '', // 用户昵称
    userProfilePhoto: '' // 用户头像
  },
  mutations: {
    /**
     * 用户登录
     * @param {*} state
     * @param {*} payload
     */
    userLogin(state, payload) {
      state.userToken = payload.userToken;
      state.userName = payload.userName;
      state.userProfilePhoto = payload.userProfilePhoto;
      // 保存数据到本地
      setLocalUserData(payload);
    },
    /**
     * 用户登录
     * @param {*} state
     * @param {*} payload
     */
    userUpdate(state, payload) {
      state.userName = payload.userName;
      state.userProfilePhoto = payload.userProfilePhoto;
      // 保存数据到本地
      setLocalUserData({ ...payload, userToken: state.userToken });
    },
    /**
     * 用户退出登录
     * @param {*} state
     */
    userLogout(state) {
      state.userToken = '';
      state.userName = '';
      state.userProfilePhoto = '';
      // 清空本地数据
      setLocalUserData({});
    }
  },
  getters: {
    /**
     * 用户登录状态
     */
    userIsLogin: state => {
      return !!state.userToken;
    },
    /**
     * 获取用户数据
     */
    userData: state => {
      return {
        userName: state.userName, // 用户昵称
        userProfilePhoto: state.userProfilePhoto // 用户头像
      };
    }
  }
};

export default moduleUser;
