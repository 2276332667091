<template>
  <div class="upload-image-wrap" v-bind:class="isShowError ? 'upload-image-wrap-error' : ''">
    <slot name="default"></slot>
    <label>
      <slot name="upload"></slot>
      <input v-bind="$attrs" type="file" v-on:change="eventFileChange" />
    </label>
    <div class="upload-image-wrap-error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import Emitter from './emitter';
export default {
  name: 'BaseUploadImage',
  /** 混入 */
  mixins: [Emitter],
  inject: ['form'],
  inheritAttrs: false, // 禁用 Attribute 继承
  /**
   * 自定义 model (双向绑定)
   */
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    beforeUpload: {
      type: Function,
      required: false,
      default: file => Boolean(file)
    },
    upload: {
      type: Function,
      required: false,
      default: (file, next) => {
        next();
      }
    },
    /** 规则验证用的属性名 */
    prop: {
      type: String,
      required: false,
      default: ''
    },
    /** model 属性 */
    value: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return { isShowError: false, errorMessage: '' };
  },
  mounted() {
    /** 在 BaseForm 组件列表中注册该组件 */
    this.dispatch('BaseForm', 'on-form-item-add', this);
  },
  destroyed() {
    /** 在 BaseForm 组件列表中移除该组件 */
    this.dispatch('BaseForm', 'on-form-item-remove', this);
  },
  methods: {
    /**
     * 输入框文件改变
     */
    eventFileChange(e) {
      let target = e.target;
      let file = target.files[0];
      /**
       * 文件上传前回调
       */
      if (!this.beforeUpload(file)) {
        // 验证不通过
        return (target.value = '');
      }
      /**
       * 文件上传回调
       * imageLink 文件传到服务器后的真实地址
       */
      this.upload(file, (imageLink = '') => {
        // 无论成功失败都需要调用此回调 清空input
        target.value = '';
        imageLink && this.$emit('update', imageLink);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.upload-image-wrap {
  position: relative;
  label {
    input {
      display: none;
    }
  }
  .upload-image-wrap-error-message {
    color: #e91e63;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    font-size: 0.9em;
    white-space: nowrap;
    z-index: 4;
    display: none;
  }
  &.upload-image-wrap-error {
    .upload-image-wrap-error-message {
      display: block;
    }
  }
}
</style>
