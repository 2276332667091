/*
 * @Author: CloverLiang
 * @Date: 2020-04-21 19:10:39
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2020-04-22 14:57:55
 * 评论模块状态管理
 */
const moduleComment = {
  namespaced: true,
  state: {
    commentIsOpenReplyArea: false, // 是否有评论展开回复区域 false => 区域关闭 true => 区域展开
    commentReplyIndexArr: [], // 展开回复区域的评论下标 eg: [0] 代表第一个评论 [1,1] 代表第二个评论的第二个子评论

    commentIsReadyToSubmit: false, // 评论是否准备好提交 false => 未准备好 true => 准备好了
    commentReplayDataArr: [] // 评论回复数据数组 eg: [1,'string'] 第一位表示类型 0 => 一级评论(文章评论) 1 => 二级评论(回复评论) 第二位表示文本内容
  },
  mutations: {
    /**
     * 改变评论回复区域状态
     */
    changeCommentReplyStatus(state, payload) {
      state.commentIsOpenReplyArea = payload.status;
      state.commentReplyIndexArr = payload.indexArr;
    },
    /**
     * 改变评论提交状态
     */
    changeCommentSubmitStatus(state, payload) {
      state.commentIsReadyToSubmit = payload.status;
      state.commentReplayDataArr = payload.replyArray;
    }
  }
};
export default moduleComment;
