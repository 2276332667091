<template>
  <div></div>
</template>

<script>
import axios from '../interface/http'; // 倒入 api

const test1 = data => {
  return axios.setPath('test1').get(data);
};

const test2 = data => {
  return axios.setPath('test2').post(data);
};

export default {
  name: 'Demo',
  created() {
    test1({
      query: {
        id: '111'
      },
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        // baseURL: "http://127.0.0.1" // 更改 baseURL
      }
    }).then(res => {
      window.console.log(res);
    });
    const test = new FormData();
    test.append('id', '111');
    test.append('name', 'sdfdsfdsf');
    test2({
      params: test,
      config: {
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded"
          'Content-Type': 'multipart/form-data'
        }
      }
    }).then(res => {
      window.console.log(res);
    });
  }
};
</script>

<style scoped lang="scss"></style>
