<template>
  <base-page-frame>
    <div class="article-category">
      <base-wrap class="article-category-wrap">
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <ul v-if="catagoryList.length > 0">
            <li v-for="item in catagoryList" :key="item.category">
              <h3>
                <router-link class="animation" :to="`/category/${item.category}`">{{
                  item.category
                }}</router-link>
                <span>{{ item.number }}</span>
              </h3>
            </li>
          </ul>
          <p v-else style="text-align: center; min-height: 100px; line-height: 100px;">
            <span>荒芜的世界</span>
          </p>
        </div>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
/**
 * 文章分类
 */
export default {
  name: 'PageCategory',
  data() {
    return {
      isMounted: false, // 页面是否首次加载完成
      catagoryList: []
    };
  },
  mounted() {
    this.$api
      .articleCatagory()
      .then(res => {
        this.catagoryList = res.map(item => {
          return {
            category: item.category,
            number: item.articleNumber
          };
        });
      })
      .finally(() => {
        this.isMounted = true;
      });
  }
};
</script>

<style scoped lang="scss">
.article-category {
  padding-top: 20px;
  a {
    color: inherit;
  }
  li {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    span {
      float: right;
    }
  }
}
</style>
