/**
 * 递归使用 call 方式this指向
 * @param componentName // 需要找的组件的名称
 * @param eventName // 事件名称
 * @param params // 需要传递的参数
 */
function broadcast(componentName, eventName, params) {
  // 循环子节点找到名称一样的子节点 否则 递归 当前子节点
  this.$children.map(child => {
    if (componentName === child.$options.name) {
      child.$emit.apply(child, [eventName].concat(params));
    } else {
      broadcast.apply(child, [componentName, eventName].concat(params));
    }
  });
}
export default {
  methods: {
    /**
     * 派发 (向上查找) (一个)
     * @param componentName // 需要找的组件的名称
     * @param eventName // 事件名称
     * @param params // 需要传递的参数
     */
    dispatch(componentName, eventName, params) {
      let parent = this.$parent || this.$root; //$parent 找到最近的父节点 $root 根节点
      let name = parent.$options.name; // 获取当前组件实例的name
      // 如果当前有节点 && 当前没名称 且 当前名称等于需要传进来的名称的时候就去查找当前的节点
      // 循环出当前名称的一样的组件实例
      while (parent && (!name || name !== componentName)) {
        parent = parent.$parent;
        if (parent) {
          name = parent.$options.name;
        }
      }
      // 有节点表示当前找到了name一样的实例
      if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params));
      }
    },
    /**
     * 广播 (向下查找) (广播多个)
     * @param componentName // 需要找的组件的名称
     * @param eventName // 事件名称
     * @param params // 需要传递的参数
     */
    broadcast(componentName, eventName, params) {
      broadcast.call(this, componentName, eventName, params);
    },
    /**
     * 获取规则
     */
    getRules() {
      let rules = this.form.rules;
      rules = rules ? rules[this.prop] : [];
      return [].concat(rules || []); //这种写法可以让规则肯定是一个数组的形式
    },
    /**
     * 获取过滤后的规则
     */
    getFilteredRule(trigger = '') {
      let rules = this.getRules();
      // !res.trigger 没有过滤方式的时候默认全部校验
      // filter 过滤出当前需要的规则
      return rules.filter(res => !res.trigger || res.trigger.indexOf(trigger) !== -1);
    },
    /**
     * 验证函数
     * @param {Boolean} trigger true => 有错误 false=> 无错误
     */
    validation(trigger = '') {
      let that = this;
      let rules = that.getFilteredRule(trigger);
      let isError = false;

      let validatorCallback = function(error) {
        if (error) {
          isError = true;
          that.isShowError = true;
          that.errorMessage = error.message || '此项为必填项';
        } else {
          isError = false;
          that.isShowError = false;
          that.errorMessage = '';
        }
      };

      for (let i = 0; i < rules.length; i++) {
        let rule = rules[i];
        // 是否有错误
        if (isError) break;

        // 1. 判断是否是必填项
        if (rule.isRequired) {
          // 此项为必填项
          validatorCallback(that.value.length <= 0);
        }
        // 2. 验证其他规则 有一个错误即停止
        if (typeof rule.validator === 'function') {
          rule.validator(that.value, validatorCallback);
        }
      }
      // 验证完成
      return isError;
    }
  }
};
