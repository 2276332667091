<template>
  <base-page-frame>
    <div class="about-wrap">
      <!-- 关于展示 -->
      <base-wrap class="about-wrap-article-item">
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <!-- 关于背景图 -->
          <user-background
            v-bind:name="articleData.articleAuthor"
            v-bind:image="articleData.articleAuthorProfilePhoto"
            v-bind:background="articleData.articleAuthorBackground"
          />

          <!-- 关于内容 -->
          <div class="about-content">
            <base-parse-html v-bind:html-str="articleData.articleDeatil"></base-parse-html>
          </div>
        </div>
      </base-wrap>
      <!-- 评论模块 -->
      <base-wrap v-if="articleData.articleIsCanComment" class="comment-area">
        <comment-area
          v-bind:article-id="articleId"
          v-bind:article-author-id="articleData.articleAuthorId"
        />
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
// 关于我的
import CommentArea from '../components/CommentArea/CommentArea';
import UserBackground from '../components/UserComponents/UserBackground';
export default {
  name: 'PageAbout',
  data() {
    return {
      isMounted: false, // 页面是否首次加载完成
      articleId: 'about', // 文章id
      /**
       * 文章数据
       */
      articleData: {
        articleId: -1, // 文章id
        articleAuthor: '', // 文章作者
        articleAuthorId: '', // 文章作者 id
        articleDeatil: '', // 文章内容
        articleIsAbout: true, // 文章是否是关于页文章
        articleIsCanComment: false, // 文章是否可以评论
        articleAuthorBackground: '', // 文章作者背景图片
        articleAuthorProfilePhoto: '' // 文章作者头像
      }
    };
  },
  mounted() {
    this.getAboutDetail();
  },
  methods: {
    /**
     * 获取关于页详细信息
     */
    getAboutDetail() {
      this.$api
        .articleDetail({ uri: { id: this.articleId } })
        .then(res => {
          this.formatAboutData(res);
          // 不是关于页文章转到普通文章详情
          if (!this.articleData.articleIsAbout) {
            return this.$router.replace({
              path: `/article/${this.articleData.articleId}${this.$route.hash}`
            });
          }
        })
        .finally(() => {
          this.isMounted = true;
        });
    },
    /**
     * 格式化关于页信息
     */
    formatAboutData(data) {
      this.articleData = {
        articleId: data.articleId, // 文章id
        articleAuthor: data.articleAuthor, // 文章作者
        articleAuthorId: data.articleAuthorId, // 文章作者 id
        articleDeatil: data.articleContent, // 文章内容
        articleIsAbout: data.articleIsAbout, // 文章是否是关于页文章
        articleIsCanComment: data.articleIsCanComment, // 文章是否可以评论
        articleAuthorBackground: data.articleAuthorBackground, // 文章作者背景图片
        articleAuthorProfilePhoto: data.articleAuthorProfilePhoto // 文章作者头像
      };
    }
  },
  components: {
    'comment-area': CommentArea,
    'user-background': UserBackground
  }
};
</script>

<style scoped lang="scss">
.about-wrap {
  text-align: center;
  padding-top: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  /** 关于展示样式 */
  .about-wrap-article-item {
    margin-bottom: 20px;
    overflow: hidden;
    /** 关于内容 */
    .about-content {
      text-align: left;
    }
  }
}
</style>
