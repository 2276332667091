<template>
  <span class="icon" :class="icon_class">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    icon_class: function() {
      return `icon-${this.icon}`;
    }
  }
};
</script>

<style scoped lang="scss">
@font-face {
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-family: 'cloverliang_website';
  src: url('fonts/cloverliang_website.eot?nxvirc');
  src: url('fonts/cloverliang_website.eot?nxvirc#iefix') format('embedded-opentype'),
    url('fonts/cloverliang_website.ttf?nxvirc') format('truetype'),
    url('fonts/cloverliang_website.woff?nxvirc') format('woff'),
    url('fonts/cloverliang_website.svg?nxvirc#cloverliang_website') format('svg');
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cloverliang_website' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<style scoped lang="scss">
/** 基德 */
.icon-kid::before {
  content: '\e913';
}
/** 设置 */
.icon-settings::before {
  content: '\e90c';
}
/** 搜索 */
.icon-search::before {
  content: '\e90f';
}
/** 月亮 */
.icon-moon::before {
  content: '\e911';
}
/** 太阳 */
.icon-sun::before {
  content: '\e912';
}
/** 对号✔ */
.icon-check::before {
  content: '\e910';
}
/** 禁止 */
.icon-forbid::before {
  content: '\e901';
}
/** 日历 */
.icon-calendar::before {
  content: '\e902';
}
/** 评论 */
.icon-chat::before {
  content: '\e903';
}
/** 笔 */
.icon-pen::before {
  content: '\e904';
}
/** 用户 */
.icon-user::before {
  content: '\e905';
}
/** 锁 */
.icon-lock::before {
  content: '\e906';
}
/** 打开的文件夹 */
.icon-folder-open::before {
  content: '\e907';
}
/** 链接 */
.icon-link::before {
  content: '\e908';
}
/** 下箭头 */
.icon-arrow-down::before {
  content: '\e909';
}
/** 鸟 */
.icon-bird::before {
  content: '\e900';
}
/** bug */
.icon-bug::before {
  content: '\e90d';
}
/** 登录 */
.icon-login::before {
  content: '\e90a';
}
/** 登出 */
.icon-logout::before {
  content: '\e90b';
}
// /** 感叹 */
// .icon-sigh::before {
//   content: "\e913";
// }
/** markdown */
.icon-markdown::before {
  content: '\e90e';
}
/** 邮件 */
.icon-mail::before {
  content: '\e914';
}
/** 验证 */
.icon-verify-user::before {
  content: '\e915';
}
/** 右箭头 */
.icon-arrow-right::before {
  content: '\e916';
}
</style>
