<template>
  <div class="base-button">
    <button
      v-bind="$attrs"
      v-bind:type="$attrs.type || 'button'"
      v-on="$listeners"
      v-bind:style="`background-color: ${bgColor}; color: ${textColor}`"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false, // 禁用 Attribute 继承
  props: {
    bgColor: {
      type: String,
      required: false,
      default: 'rgba(0, 173, 167, 1)'
    },
    textColor: {
      type: String,
      required: false,
      default: 'rgba(255, 255, 255, 1)'
    }
  }
};
</script>

<style scoped lang="scss">
.base-button {
  text-align: center;
  button {
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.2s;
    border-radius: 3px;
    word-break: keep-all;
    width: 100%;
    &:active {
      background-color: rgba(48, 48, 48, 1) !important;
    }
  }
}
</style>
