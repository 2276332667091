<template>
  <base-page-frame>
    <div class="user-center">
      <!-- 个人中心 -->
      <base-wrap>
        <div v-inline-loading="{ show: !isMounted, color: 'black' }">
          <user-background
            class="user-center-background"
            v-bind:name="userData.userName"
            v-bind:image="userData.userProfilePhoto"
            v-bind:background="userData.userBackground"
          />
          <!-- 动态组件 -->
          <div class="user-center-operation">
            <ul class="user-center-operation-left-list">
              <li
                v-for="(component, index) in componentsList"
                v-bind:key="component.componentName"
                v-on:click.stop="currentComponentIndex = index"
                class="user-center-operation-left-list-item"
                v-bind:class="
                  currentComponentData.componentName === component.componentName
                    ? 'user-center-operation-left-list-item-active'
                    : ''
                "
              >
                <span>{{ component.componentText }}</span>
                <base-icon class="user-center-operation-left-list-item-icon" icon="arrow-right" />
              </li>
            </ul>
            <div class="user-center-operation-right-view">
              <div class="user-center-operation-right-view-title">
                {{ currentComponentData.componentText }}
              </div>
              <keep-alive v-bind:exclude="'UserNotification'">
                <component
                  v-bind:is="currentComponentData.componentName"
                  v-bind:origin-user-data="userData"
                  v-on:user-info-update="eventUserInfoUpdate"
                ></component>
              </keep-alive>
            </div>
          </div>
        </div>
      </base-wrap>
    </div>
  </base-page-frame>
</template>

<script>
import UserBackground from '../components/UserComponents/UserBackground';
import UserInformation from '../components/UserComponents/UserInformation';
import UserNotification from '../components/UserComponents/UserNotification';
import UserAccount from '../components/UserComponents/UserAccount';

export default {
  name: 'PageUserCenter',
  data() {
    return {
      isMounted: false,
      /**
       * 用户数据
       */
      userData: {
        userName: '', // 用户名
        userAccount: '', // 用户账户地址
        userBackground: '', // 用户背景图片
        userProfilePhoto: '', // 用户头像
        userWebsite: '', // 用户个人网站地址
        userNoticeStatus: false, // 用户是否接收通知消息
        userEmailNoticeStatus: false, // 用户是否接收邮件通知消息
        userLastLoginTime: '', // 用户上一次登录时间
        userLastLoginIp: '' // 用户上一次登录ip地址
      },
      /**
       * 组件列表
       */
      componentsList: [
        { componentName: 'user-infomation', componentText: '我的信息' },
        { componentName: 'user-notification', componentText: '通知中心' },
        { componentName: 'user-account', componentText: '账号相关' }
      ],
      currentComponentIndex: 0 // 当前显示的组件下标
    };
  },
  computed: {
    /**
     * 当前展示的组件数据
     */
    currentComponentData: function() {
      return this.componentsList[this.currentComponentIndex];
    }
  },
  mounted() {
    /**
     * 获取用户数据
     */
    this.getUserInfo();
  },
  methods: {
    /**
     * 获取用户信息
     */
    getUserInfo() {
      this.$api.getUserInfo().then(res => {
        this.formatUserData(res);
        // 更新本地 store 数据
        this.$store.commit({ type: 'user/userUpdate', ...this.userData });
        this.isMounted = true;
      });
    },
    /**
     * 格式化用户数据
     */
    formatUserData(data) {
      this.userData = {
        userName: data.userName, // 用户名
        userAccount: data.userAccount, // 用户账户地址
        userBackground: data.userBackground, // 用户背景图片
        userProfilePhoto: data.userProfilePhoto, // 用户头像
        userWebsite: data.userWebsite, // 用户个人网站地址
        userLastLoginTime: data.userLastLoginTime, // 用户上一次登录时间
        userLastLoginIp: data.userLoginIp, // 用户上一次登录ip地址
        userNoticeStatus: Boolean(data.userNoticeFlag), // 用户是否接收通知消息
        userEmailNoticeStatus: Boolean(data.userEmailNoticeFlag) // 用户是否接收邮件通知消息
      };
    },
    /**
     * 用户数据更新成功，更新用户数据
     */
    eventUserInfoUpdate() {
      this.getUserInfo();
    }
  },
  components: {
    'user-account': UserAccount,
    'user-background': UserBackground,
    'user-notification': UserNotification,
    'user-infomation': UserInformation
  }
};
</script>

<style scoped lang="scss">
.user-center {
  padding-top: 20px;
  .user-center-background {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
  .user-center-operation {
    margin-top: 40px;
    .user-center-operation-left-list {
      width: 140px;
      float: left;
      padding-top: 20px;
      .user-center-operation-left-list-item {
        margin-bottom: 20px;
        padding: 0px 10px 10px;
        cursor: pointer;
        box-sizing: border-box;
        .user-center-operation-left-list-item-icon {
          margin-left: 10px;
        }
      }
      .user-center-operation-left-list-item-active {
        color: #f44336;
      }
    }
    .user-center-operation-right-view {
      min-height: 200px;
      margin-left: 140px;
      padding-left: 20px;
      box-sizing: border-box;
      border-left: 1px solid rgba(34, 34, 34, 1);
      .user-center-operation-right-view-title {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(34, 34, 34, 1);
        margin-bottom: 20px;
        font-weight: bolder;
        font-size: 1.1em;
      }
    }
  }
}
/** 媒体查询 */
@media only screen and (max-width: 700px) {
  .user-center {
    .user-center-operation {
      display: block;
      .user-center-operation-left-list {
        float: none;
        width: 100%;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .user-center-operation-left-list-item {
          margin-bottom: 10px;
          .user-center-operation-left-list-item-icon {
            display: none;
          }
        }
      }
      .user-center-operation-right-view {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        padding-top: 20px;
        border-top: 1px solid #222222;
        font-size: 0.9em;
        .user-center-operation-right-view-title {
          display: none;
        }
      }
    }
  }
}
</style>
