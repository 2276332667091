/*
 * @Author: CloverLiang
 * @Date: 2022-04-15 16:09:25
 * @Last Modified by: CloverLiang
 * @Last Modified time: 2022-04-15 16:12:22
 * website 基本配置
 */
const moduleBase = {
  namespaced: true,
  state: {
    STATIC_HOST: '' // 静态资源 host
  },
  mutations: {
    /**
     * 设定基本配置
     * @param {*} state
     * @param {*} payload
     */
    setConfig(state, payload) {
      state.STATIC_HOST = payload.staticHost;
    }
  },
  getters: {
    /**
     * 用户登录状态
     */
    STATIC_HOST: state => {
      return state.STATIC_HOST || '';
    }
  }
};

export default moduleBase;
